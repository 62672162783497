// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    font-family: Karla,Lato,Arial,sans-serif,Helvetica;;
}

.contenedor{
    background-color: #fff;
    padding: 20px 20px;
    margin-top: calc(20vh);
}

.button{
    border-radius: 20px !important;
    padding: 10px 20px !important;
}

.cursorPointer{
    cursor: pointer;
}

.w-90{
    width: 90%;
}

.fadeOut{
    opacity:0;
    width:0;
    height:0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn{
    opacity:1;
    width:100px;
    height:100px;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    background-color: #0ea1b8 !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #197589 !important;
}

.gridBorder{
    border: 1px dashed black;
    height: calc(25vh);
    width: 90%;
    background-color: #ffffff;
  }

  .cajaImg{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    background: #333944;
  }
  .boxImg {
    width: 100%;
    height: calc(24.5vh);
    background: #f5f3f3;
    overflow: hidden;
  }
  img, picture {
    margin: 0;
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/estilo.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;AACtD;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,8BAA8B;IAC9B,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;IACT,OAAO;IACP,QAAQ;IACR,2DAA2D;AAC/D;AACA;IACI,SAAS;IACT,WAAW;IACX,YAAY;IACZ,sDAAsD;AAC1D;;AAEA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,UAAU;IACV,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,oBAAoB;IACpB,mBAAmB;IACnB,gBAAgB;EAClB;EACA;IACE,SAAS;IACT,eAAe;AACnB","sourcesContent":["*{\n    font-family: Karla,Lato,Arial,sans-serif,Helvetica;;\n}\n\n.contenedor{\n    background-color: #fff;\n    padding: 20px 20px;\n    margin-top: calc(20vh);\n}\n\n.button{\n    border-radius: 20px !important;\n    padding: 10px 20px !important;\n}\n\n.cursorPointer{\n    cursor: pointer;\n}\n\n.w-90{\n    width: 90%;\n}\n\n.fadeOut{\n    opacity:0;\n    width:0;\n    height:0;\n    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;\n}\n.fadeIn{\n    opacity:1;\n    width:100px;\n    height:100px;\n    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;\n}\n\n.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {\n    background-color: #0ea1b8 !important;\n}\n.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {\n    background-color: #197589 !important;\n}\n\n.gridBorder{\n    border: 1px dashed black;\n    height: calc(25vh);\n    width: 90%;\n    background-color: #ffffff;\n  }\n\n  .cajaImg{\n    display: flex;\n    flex-flow: column wrap;\n    justify-content: center;\n    align-items: center;\n    background: #333944;\n  }\n  .boxImg {\n    width: 100%;\n    height: calc(24.5vh);\n    background: #f5f3f3;\n    overflow: hidden;\n  }\n  img, picture {\n    margin: 0;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import { Divider, Select, MenuItem, TextField } from "@mui/material";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import "../../css/estilo.css";
import InputLabel from "@mui/material/InputLabel";

const ConfMail = (datos) => {
  const { open, handleClose, configuracion, handleChange } = datos;

  React.useEffect(() => {
    // limpiar();
  }, [open]);

  const style = {
    width: "50%",
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Configuracion correo electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Con esta opción se configuran los parametros para el envío de correo
            electrónico a los clientes
          </DialogContentText>
          <form>
            <Grid container spacing={2}>
              <Grid size={12}>
                <TextField
                  id="mailhost"
                  name="mailhost"
                  label="mail.smtp.host"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleChange}
                  onLoad={handleChange}
                  value={configuracion.mailhost}
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  id="mailport"
                  name="mailport"
                  label="mail.smtp.port"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mailport}
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  id="mailport2"
                  name="mailport2"
                  label="mail.smtp.socketFactory.port"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mailport2}
                  fullWidth
                />
              </Grid>
              <Grid size={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.transport.protocol
                </InputLabel>
              </Grid>
              <Grid size={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="protocol"
                  name="protocol"
                  style={style}
                  value={configuracion.protocol}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="smtp">SMTP</MenuItem>
                  <MenuItem value="smtps">SMTPS</MenuItem>
                </Select>
              </Grid>
              <Grid size={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.smtp.socketFactory.fallback
                </InputLabel>
              </Grid>
              <Grid size={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="fallback"
                  name="fallback"
                  style={style}
                  value={configuracion.fallback}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </Grid>
              <Grid size={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.smtp.starttls.required
                </InputLabel>
              </Grid>
              <Grid size={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="starttls"
                  name="starttls"
                  style={style}
                  value={configuracion.starttls}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </Grid>
              <Grid size={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.smtp.ssl
                </InputLabel>
              </Grid>
              <Grid size={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="ssl"
                  name="ssl"
                  style={style}
                  value={configuracion.ssl}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </Grid>
              <Grid size={12}>
                <TextField
                  id="mail"
                  name="mail"
                  label="Correo Emisor"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mail}
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  id="mailpass"
                  name="mailpass"
                  label="Contraseña"
                  type="password"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mailpass}
                  fullWidth
                />
              </Grid>
              <Divider variant="middle" />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>volver</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfMail;

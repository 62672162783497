import React from 'react';
import {  Paper, FormControl, TextField, Button, InputLabel, Select, MenuItem, Stack, Pagination} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TableContainer, Table,TableHead, TableBody, TableCell, TableRow} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState } from 'react';

export const Resumen = () =>{
    
    const [resumen, setResumen] = useState({
        dNomEmi:'', //razon social emisor
        dRucEm:'',  //ruc emisor
        dNomRec:'', //nombre o razon social receptor
        dRucRec:'', //ruc receptor
        dDesMotEmi:'', //motivo de emision nota
        iTiOpe:'', //tipo de operacion
        iTImp:'', //tipo impuesto afectado
        DigestValue:'', //digest value 
        //hash qr ---> concatenar nVersion, Id(cdc), dFeEmiDE, dRucRec/dNumIDRec, dTotGralOpe, dTotIVA, cItems, DigestValue, IdCSC, cHashQR 
        dSubExe:'', //exentas
        dSubExe:'', //exoneradas
        dSub5:'', //5%
        dSub1o:'', //10%
        subtotal:'', //---> dSub5 - dSub10  
        dTotOpe:'',
        dTotOpeItem:'', //monto de la operacion  
        dTiCam:'',//tipo de cambio
        dTotOpeGs:'',//monto total de la operacion en gs 
        dDesMoneOpe:'',//moneda de la operacion
        cItems:'',//cantidad de items
        dProtAut:'', //num de transaccion de SIFEN
        dEstRes:'',//Estado
        dFecProc:'',//fecha hora
        dSisFact:'',//sistema de facturacion
    });

    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <FormControl sx={{margin:-50, marginLeft:30}}>        
                        <div style={{marginLeft: -110}}>
                            <TextField id="outlined-size-small" size="small" label="Razón social del Emisor" variant="outlined" />
                            <TextField id="outlined-size-small" size="small" label="RUC" variant="outlined" sx={{marginLeft:2}}/>
                        </div>
                        <div style={{margin: 10, marginLeft: -100}}>
                            <TextField id="outlined-size-small" size="small" label="Nombre o Razón Social del Receptor" variant="outlined" />
                            <TextField id="outlined-size-small" size="small" label="RUC" variant="outlined" sx={{marginLeft:2}}/>
                        </div>
                        <div style={{marginLeft: -40}}>
                            <TextField id="outlined-size-small" size="small" label="Motivo de Emisión de Nota" variant="outlined" />
                            <TextField id="outlined-size-small" size="small" label="Tipo de Operación" variant="outlined" sx={{marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Tipo de Impuesto Afectado" variant="outlined" sx={{marginLeft:2}}/>
                        </div>
                        <div style={{margin: 10, marginLeft: -110}}>
                            <TextField id="outlined-size-small" size="small" label="Digest Value" variant="outlined" />
                            <TextField id="outlined-size-small" size="small" label="Hash QR" variant="outlined" sx={{marginLeft:2}}/>
                        </div>
                    </FormControl> 
                    <TableContainer component={Paper} sx={{width: 390, height: 260, margin: -28, marginLeft:27}}>
                        <label style={{fontFamily:'Arial Black', fontSize:14}}>Datos de la Operación</label>
                            <Table sx={{ width: 10 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="right">Exentas</TableCell>
                                        <TableCell align="right">Exoneradas</TableCell>
                                        <TableCell align="right">5%</TableCell>
                                        <TableCell align="right">10%</TableCell>
                                    </TableRow>
                                    <TableHead>
                                        <TableRow>
                                            Subtotal
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            IVA
                                        </TableRow>
                                    </TableHead>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table sx={{ maxwidth: 200 }} aria-label="simple table" fullWidth>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Monto de la Operación</TableCell>
                                        <TableCell align="right">Tipo de Cambio</TableCell>
                                        <TableCell align="right">Monto Total de la Operación en Gs.</TableCell>
                                    </TableRow>
                                </TableHead>    
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table sx={{ }} aria-label="simple table" fullWidth>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Moneda de la Operación</TableCell>
                                        <TableCell align="right">Cantidad de Items</TableCell>
                                    </TableRow>
                                </TableHead>    
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>    
                    </TableContainer>
                    <FormControl sx={{width:200, margin:0, marginLeft:80}}>        
                        <TextField id="outlined-size-small" size="small" label="N° de Transacción de SIFEN" variant="outlined" />
                        <TextField id="outlined-size-small" size="small" label="Estado" variant="outlined" style={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Fecha y Hora" variant="outlined" style={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Sistema de Facturación" variant="outlined" style={{marginTop:2}}/>
                    </FormControl>                
                </Grid>           
            </Grid>         
        </React.Fragment>
    )
}

export const Emisor = () =>{

    const [emisor, setEmisor] = useState({
        dNomEmi:'',//razon social del emisor
        dNomFanEmi:'',//nombre de fantasia
        dRucEm:'',//ruc
        dDesActEco:'',//actividad economica
        dDirEmi:'',//direccion donde se emitio el DTE
        dTelEmi:'',//telefono del local de emision DTE
        dEmailE:'',//correo elect del emisor
        dDesCiuEm:'',//ciudad

    });
    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <FormControl sx={{width: 400, margin:-50, marginLeft:30}}>        
                        <TextField id="outlined-size-small" size="small" label="Razón social del Emisor" variant="outlined" />
                        <TextField id="outlined-size-small" size="small" label="Nombre de fantasía" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="RUC" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Actividad Económica" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Dirección donde se emitió el DTE" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Teléfono local de emisión de DE" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Correo electrónico del emisor" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Ciudad" variant="outlined" sx={{marginTop:2}}/>
                    </FormControl>
                </Grid>
            </Grid>         
        </React.Fragment>
    )
}

export const Receptor = () =>{
    
    const [receptor, setReceptor] = useState({
        dNomRec:'',//nombre o razon social
        iNatRec:'',//naturaleza del recpetor
        iTiContRec:'',//tipo de contribuyente
        dRucRec:'',//ruc
        dDirRec:'',//direccion
        telefono:'',// ---> dTelRec - dCelRec:'',//
        dEmailRec:'',//correo electronico
        dDesPaisRe:'',//pais
    });

    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <FormControl sx={{width: 400, margin:-50, marginLeft:30}}>        
                        <TextField id="outlined-size-small" size="small" label="Nombre o Razón social del Receptor" variant="outlined" />
                        <TextField id="outlined-size-small" size="small" label="Naturaleza del Receptor" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Tipo de Contribuyente" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="RUC" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Dirección" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Teléfono" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Correo electrónico" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="País" variant="outlined" sx={{marginTop:2}}/>
                    </FormControl>
                </Grid>
            </Grid>         
        </React.Fragment>
    )
}

export const ItemsOperac = () =>{

    const [items, setItems] = useState({
        dCodInt:'',//codigo  
        dDesProSer:'',//desc del producto
        cantidad:'',// ---> dCantProSer cItems:'',//
        dDesUniMed:'',//unidad de medida
        //valor total de la operacion:'',// ------> dTotOpeItem - dTotOpeGs
        dPUniProSer:'',//precio unitario del producto
        dDescGloItem:'',//descuento particular sobre el precio unitario por item
        dTotBruOpeItem:'',//total bruto de la operacion
        dPorcDesIt:'',//porcentaje de descuento por item
        dDesAfecIVA:'',//forma de afectacion tributaria del iva 
        dPropIVA:'',//prporcion gravada de iva
        dTasaIVA:'',//tasa del iva
        dBasGravIVA:'',//base gravada del iva por item
        dLiqIVAItem:'',//liquidacion del iva por item
    });

    const item = 0;
    const count = 0;

    for(var i=0; i < item; i++){
        if(item === 'itemD'){
            count++;
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>    
                    <FormControl sx={{margin:-45, marginLeft:40}}>        
                        <div style={{marginLeft: -110}}>
                            <TextField id="outlined-size-small" size="small" label="Código" variant="outlined"/>
                            <TextField id="outlined-size-small" size="small" label="Descripción del Producto y/o Servicio" variant="outlined" sx={{marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Cantidad" variant="outlined" sx={{marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Unidad de Medida" variant="outlined" sx={{marginTop:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Valor total de la Operación por Item" variant="outlined" sx={{marginTop:2, marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Precio unitario del Producto y/o Servicio" variant="outlined" sx={{marginTop:2, marginLeft:2}}/>
                        </div>
                        <div style={{margin: 10, marginLeft: -110}}>
                            <TextField id="outlined-size-small" size="small" label="Descuento particular sobre el precio unitario por ítem " variant="outlined" sx={{marginTop:1, marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Total bruto de la operación por ítem" variant="outlined" sx={{marginTop:1, marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Porcentaje de descuento por Item" variant="outlined" sx={{marginTop:1, marginLeft:2}}/>
                        </div>
                        <div style={{marginLeft: -100}}>
                            <TextField id="outlined-size-small" size="small" label="Forma de Afectación Tributaria del IVA" variant="outlined" sx={{marginTop:1}}/>
                            <TextField id="outlined-size-small" size="small" label="Proporción Gravada de IVA" variant="outlined" sx={{marginTop:1, marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Tasa del IVA" variant="outlined" sx={{marginTop:1, marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Base gravada del IVA por ítem" variant="outlined" sx={{marginTop:2, marginLeft:2}}/>
                            <TextField id="outlined-size-small" size="small" label="Liquidación del IVA por ítem" variant="outlined" sx={{marginTop:2, marginLeft:2}}/>
                        </div>
                    </FormControl>             
                </Grid>
            </Grid>
            <Stack>
                <Pagination count={1} sx={{marginLeft:50}} />
            </Stack>
        </React.Fragment>
    );
}

export const TotSub = () =>{
    
    const[totales, setTotales]= useState({
        dSub5:'',// subtotal de la operacio con iva incluido a la tasa de %  
        dSub10:'',
        dTotOpe:'',
        dTotOpeItem:'',//total general de la operacion - dTotOpeGs
        dIVA5:'',//liquidacion del iva a la tasa del %  
        dIVA10:'',
        dLiqTotIVA5:'',//liquidacion toatal del iva  
        dLiqTotIVA10:'',
        dBaseGrav5:'',//total base gravada de la tasa del % 
        dBaseGrav10:'',
        dTBasGraIVA:'',//total base gravada de iva
        dDesMoneOpe:'',//moneda de la operacion

    });

    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <FormControl sx={{width: 400, margin:-50, marginLeft:30}}>        
                        <TextField id="outlined-size-small" size="small" label="Subtotal de la Operación con IVA incluido a la tasa de %" variant="outlined" />
                        <TextField id="outlined-size-small" size="small" label="Total de la Operación" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Total General de la Operación" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Liquidación del IVA a la tasa del %" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Liquidación Total del IVA" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Total Base Gravada de la Tasa del %" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Total Base Gravada de IVA" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Moneda de la Operación" variant="outlined" sx={{marginTop:2}}/>
                    </FormControl>
                </Grid>
            </Grid>         
        </React.Fragment>
    )
}

export const DocumeAsoci = () =>{

    const[ asoc, setAsoc] = useState({
         Id:'',//cdc del dte referenciado
        dDesTipDocAso:'',//tipo de documento asociado
    });

    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <FormControl sx={{width: 400, margin:-50, marginLeft:30}}>        
                        <div>
                            <TextField id="outlined-size-small" size="small" label="CDC del DTE Referenciado" variant="outlined"/>
                            <Button variant="contained" size="small" startIcon={<SearchOutlinedIcon/>} style={{margin:6, marginLeft:5}}/>
                        </div>
                        <TextField id="outlined-size-small" size="small" label="Tipo de Documento Asociado" variant="outlined" sx={{marginTop:2}}/>
                        
                    </FormControl>
                </Grid>
            </Grid>         
        </React.Fragment>
    )
}

export const OtroGrup = () =>{

    const [otros, setOtros] = useState({
        dNroMed:'',//num de medidor
        dActiv:'',//cod de actividad
        dCateg:'',//cod de categoria
        dLecAnt:'',//lectura anterior
        dLecAct:'',//lectura actual
        dConKwh:'',//consumo
    });

    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <FormControl sx={{width: 400, margin:-50, marginLeft:20}}>        
                        <label style={{fontFamily:'Arial Black', fontSize:16}}>Sector de Energía Eléctrica</label>
                        <TextField id="outlined-size-small" size="small" label="Número de medidor" variant="outlined" />
                        <TextField id="outlined-size-small" size="small" label="Código de actividad" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Código de categoría" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Lectura anterior" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Lectura actual" variant="outlined" sx={{marginTop:2}}/>
                        <TextField id="outlined-size-small" size="small" label="Consumo" variant="outlined" sx={{marginTop:2}}/>
                    </FormControl>
                </Grid>
            </Grid>         
        </React.Fragment>   
    )
}

export const EvenRecep = () =>{
    return(
        <React.Fragment>
            <Grid container>
                <Grid xs={6}>
                    <div style={{margin:-420}}>        
                        <label style={{margin:-180, marginLeft:450, fontFamily:'Arial Black', fontSize:14}}>Gestionar Evento</label>
                        <FormControl sx={{width: 400, marginTop:5}}>
                            <InputLabel id="demo-simple-select-filled-label">Seleccionar Evento</InputLabel>
                            <Select labelId="demo-simple-select-filled-label" id="demo-simple-select-filled" 
                            name="estado">
                                <MenuItem value='Conformidad'>Conformidad</MenuItem>
                                <MenuItem value='Disconformidad'>Disconformidad</MenuItem>
                                <MenuItem value='Desconocimiento'>Desconocimiento</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ margin:'auto'}}>
                        <label style={{fontFamily:'Arial Black', fontSize:14}}>Eventos Realizados</label>
                    </div>
                </Grid>
            </Grid>         
        </React.Fragment>
    )
}
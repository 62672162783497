// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu{
    margin: 10px 10px;
    min-height: calc(97vh);
    border-radius: 20px !important;
    /* background: linear-gradient(195deg, rgb(212 212 219), rgb(178 181 181)); */
}

.menu span{
    text-align: left;
    font-size: 19px;
}

.menu ul{
    height: calc(45vh);
}

.menu li{
    padding: 10px 15px;
}`, "",{"version":3,"sources":["webpack://./src/css/menu.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,8BAA8B;IAC9B,6EAA6E;AACjF;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".menu{\n    margin: 10px 10px;\n    min-height: calc(97vh);\n    border-radius: 20px !important;\n    /* background: linear-gradient(195deg, rgb(212 212 219), rgb(178 181 181)); */\n}\n\n.menu span{\n    text-align: left;\n    font-size: 19px;\n}\n\n.menu ul{\n    height: calc(45vh);\n}\n\n.menu li{\n    padding: 10px 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

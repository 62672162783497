import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import { validateField, validateRuc } from "../../Datos/Validacion";
import "../../css/estilo.css";
import UsrInput from "../login/UsrInput";
import PassInput from "../login/PassInput";
import { toast } from "react-toastify";
import { validUsuario, cancelarDoc, enviarMailCancelado } from "../../apis/api";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";

const CancelarDoc = (datos) => {
  const { open, handleClose, selected } = datos;

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    ruc: "",
    motivo: "",
  });

  React.useEffect(() => limpiar(), [open]);

  const [errores, setErrores] = React.useState({
    password: "",
    ruc: "",
    motivo: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const limpiar = () => {
    setErrores({
      password: "",
      ruc: "",
      motivo: "",
    });
    setValues({
      password: "",
      showPassword: false,
      ruc: "",
      motivo: "",
    });
  };

  const handleClick = async () => {
    const error = {
      ruc: validateRuc(values.ruc),
      password: validateField(values.password, "password"),
      motivo: validateField(values.motivo, "motivo"),
    };
    setErrores(error);
    if (error.ruc === "" && error.password === "" && error.motivo === "") {
      let resp = await validUsuario(values);
      if (resp.estado === false) {
        toast.error("Ruc o contraseña incorrectos", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        let r = await cancelarDoc(selected.cdc, values.motivo);
        if (r.estado === "Aprobado") {
          toast.success("Documento cancelado con exito", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          await enviarMailCancelado(selected);
          handleClose();
        } else {
          toast.error(`Proceso ${r.estado} ${r.mensaje}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
        }
      }
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cancelación documento electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Con esta opcion se cancela los documentos con menos de 48hs de
            emitidos.
          </DialogContentText>
          <form>
            <Grid container spacing={2}>
              <Grid size={12}>
                <UsrInput
                  handleChange={handleChange("ruc")}
                  value={values.ruc}
                  type={"text"}
                  label="R.U.C."
                  helperText={errores.ruc}
                  error={errores.ruc === "" ? false : true}
                >
                  <PersonOutlinedIcon />
                </UsrInput>
              </Grid>
              <Grid size={12}>
                <PassInput
                  handleChange={handleChange}
                  values={values}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  helperText={errores.password}
                  error={errores.password === "" ? false : true}
                />
              </Grid>
              <Grid size={12}>
                <UsrInput
                  handleChange={handleChange("motivo")}
                  value={values.motivo}
                  type={"text"}
                  label="Motivo"
                  helperText={errores.motivo}
                  error={errores.motivo === "" ? false : true}
                >
                  <LiveHelpOutlinedIcon />
                </UsrInput>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>volver</Button>
          <Button onClick={handleClick}>Cancelar documento</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelarDoc;

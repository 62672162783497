import React, { Fragment, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../login/Login";
import Principal from "../principal/Principal";
import { SessionContext } from "../../hook/SessionContext";
import Configuracion from "../configuracion/Configuracion";
import Certificado from "../certificado/Certificado";
import Reportes from "../reportes/Reportes";
import PanelConfig from "../configuracion/PanelConfig";
import PanelKude from "../configuracion/PanelKude";
import ConsultaDE from "../eventos/ConsultaDE";
import ConsultaLote from "../eventos/ConsultaLote";
import Pruebas from "../pruebas/Pruebas";
import Mails from "../mails/Mails";

const Ruteo = () => {
  const { token } = useContext(SessionContext);
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/menu"
            element={token === "" || token === null ? <Login /> : <Principal />}
          />
          <Route
            path="/reportes"
            element={token === "" || token === null ? <Login /> : <Reportes />}
          />
          <Route
            path="/pruebas"
            element={token === "" || token === null ? <Login /> : <Pruebas />}
          />
          <Route
            path="/configuracion"
            element={
              token === "" || token === null ? <Login /> : <Configuracion />
            }
          >
            <Route path="/configuracion" element={<PanelConfig />} />
            <Route path="/configuracion" element={<PanelKude />} />
          </Route>
          <Route
            path="/certificado"
            element={
              token === "" || token === null ? <Login /> : <Certificado />
            }
          />
          <Route
            path="/mails"
            element={token === "" || token === null ? <Login /> : <Mails />}
          />
          <Route
            path="/consultaDE"
            element={
              token === "" || token === null ? <Login /> : <ConsultaDE />
            }
          ></Route>
          <Route
            path="/consultaLote"
            element={
              token === "" || token === null ? <Login /> : <ConsultaLote />
            }
          ></Route>
          <Route
            path="/error"
            element={token === "" || token === null ? <Login /> : null}
          ></Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default Ruteo;

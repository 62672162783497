import React from "react";
import { Button, Alert } from "@mui/material";
import Grid from "@mui/material/Grid2";

const styleM = {
  marginBottom: "10px",
  marginLeft: "10px",
};

const WebService = (props) => {
  const {
    handleSendWS,
    handleOpenDoc,
    lis_FE,
    lis_NC,
    lis_ND,
    lis_AU,
    lis_NR,
    tipoWs,
    enviado,
  } = props;

  return (
    <Grid container>
      <Grid size={6} style={{ marginBottom: "5px" }}>
        <Alert
          severity={
            tipoWs === "Receptor"
              ? enviado.conformidad
                ? "success"
                : "error"
              : enviado.fe
              ? "success"
              : "error"
          }
          style={{ width: "90%" }}
        >
          {tipoWs === "Receptor" ? "CONFORMIDAD" : "Factura Electrónica"}
        </Alert>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="contained"
          color="info"
          onClick={() => {
            handleSendWS(1, tipoWs);
          }}
        >
          Enviar
        </Button>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="outlined"
          onClick={() => handleOpenDoc(lis_FE)}
        >
          Verificar
        </Button>
      </Grid>

      <Grid size={6} style={{ marginBottom: "5px" }}>
        <Alert
          severity={
            tipoWs === "Receptor"
              ? enviado.disconformidad
                ? "success"
                : "error"
              : enviado.nc
              ? "success"
              : "error"
          }
          style={{ width: "90%" }}
        >
          {tipoWs === "Receptor"
            ? "DISCONFORMIDAD"
            : "Nota Crédito Electrónica"}
        </Alert>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="contained"
          color="info"
          onClick={() => {
            handleSendWS(5, tipoWs);
          }}
        >
          Enviar
        </Button>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="outlined"
          onClick={() => handleOpenDoc(lis_NC)}
        >
          Verificar
        </Button>
      </Grid>

      <Grid size={6} style={{ marginBottom: "5px" }}>
        <Alert
          severity={
            tipoWs === "Receptor"
              ? enviado.desconocimiento
                ? "success"
                : "error"
              : enviado.nd
              ? "success"
              : "error"
          }
          style={{ width: "90%" }}
        >
          {tipoWs === "Receptor"
            ? "DESCONOCIMIENTO"
            : "Nota Débito Electrónica"}
        </Alert>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="contained"
          color="info"
          onClick={() => {
            handleSendWS(6, tipoWs);
          }}
        >
          Enviar
        </Button>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="outlined"
          onClick={() => handleOpenDoc(lis_ND)}
        >
          Verificar
        </Button>
      </Grid>

      <Grid size={6} style={{ marginBottom: "5px" }}>
        <Alert
          severity={
            tipoWs === "Receptor"
              ? enviado.notificacion
                ? "success"
                : "error"
              : enviado.au
              ? "success"
              : "error"
          }
          style={{ width: "90%" }}
        >
          {tipoWs === "Receptor"
            ? "NOTIFICACIÓN DE RECEPCIÓN"
            : "AutoFactura Electrónica"}
        </Alert>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="contained"
          color="info"
          onClick={() => {
            handleSendWS(4, tipoWs);
          }}
        >
          Enviar
        </Button>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="outlined"
          onClick={() => handleOpenDoc(lis_AU)}
        >
          Verificar
        </Button>
      </Grid>

      <Grid size={6} style={{ marginBottom: "5px" }}>
        <Alert
          severity={
            tipoWs === "Receptor"
              ? enviado.ajuste
                ? "success"
                : "error"
              : enviado.nr
              ? "success"
              : "error"
          }
          style={{ width: "90%" }}
        >
          {tipoWs === "Receptor"
            ? "AJUSTE DE EVENTO DE RECEPTOR"
            : "Nota de Remisión Electrónica"}
        </Alert>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="contained"
          color="info"
          onClick={() => {
            handleSendWS(7, tipoWs);
          }}
        >
          Enviar
        </Button>
      </Grid>
      <Grid size={3}>
        <Button
          style={{ styleM, width: "90%" }}
          variant="outlined"
          onClick={() => handleOpenDoc(lis_NR)}
        >
          Verificar
        </Button>
      </Grid>
    </Grid>
  );
};

export default WebService;

import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import EnhancedTable from "./TablaPrin";
import { getSession } from "../../apis/Session";
import { listarDocs } from "../../apis/api";
import { SearchRounded } from "@mui/icons-material";

const GrillaDoc = () => {
  const [rows, setRows] = useState([]);
  const [registros, setRegistros] = useState(0);
  const [filtro, setFiltro] = useState({
    token: getSession().token,
    ruc: getSession().ruc,
    cdc: "",
    lote: "",
    fecha: "",
    fecini: "",
    fecfin: "",
    envmail: "",
    nombre: "",
    suc: "",
    exp: "",
    nrocmp: "",
    tipcmp: "",
    condicion: "",
    limit: 100,
    offset: 0,
    orden: "fecha",
    tipOrden: "desc",
  });

  const calcula = async (docs) => {
    let dRow = [];
    if (docs !== undefined) {
      if (docs.estado !== false) {
        docs.forEach((d) => {
          let tipdoc;
          switch (d.tipTransac) {
            case "Factura electrónica":
              tipdoc = "Factura";
              break;
            case "Nota de crédito electrónica":
              tipdoc = "Nota de crédito";
              break;
            case "Autofactura electrónica":
              tipdoc = "Autofactura";
              break;
            case "Nota de débito electrónica":
              tipdoc = "Nota de débito";
              break;
            case "Nota de remisión electrónica":
              tipdoc = "Nota de remisión";
              break;
            default:
              tipdoc = d.tipTransac;
              break;
          }
          if (d.motivo !== "CDC duplicado" && d.cdc !== undefined) {
            dRow.push({
              cdc: d.cdc,
              comprobante: tipdoc,
              fecha: d.fechaDE2,
              cliente: d.cliNom,
              numero: `${d.dsuc}-${d.dexp}-${d.nroFac}`,
              moneda: d.moneda,
              total: d.total,
              lote: d.csc,
              estado: d.condicion,
              climail: d.climail,
              motivo: d.motivo,
              envioMail: d.envioMail,
              codigores: d.codigores,
            });
          }
        });
      }
    }
    return dRow;
  };

  const stylePad = {
    padding: "2% 1% 0%",
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "tipcmp":
        if (event.target.value === "Todos") {
          setFiltro({ ...filtro, [event.target.name]: "" });
        } else {
          setFiltro({ ...filtro, [event.target.name]: event.target.value });
        }
        break;
      case "condicion":
        if (event.target.value === "Todos") {
          setFiltro({ ...filtro, [event.target.name]: "" });
        } else {
          setFiltro({ ...filtro, [event.target.name]: event.target.value });
        }
        break;
      default:
        setFiltro({ ...filtro, [event.target.name]: event.target.value });
    }
    handleChangePage(event, 1);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setFiltro({ ...filtro, [event.target.name]: event.target.value });
      handleChangePage(event, 1);
    } else {
      if (event.target.value.length === 0) {
        setFiltro({ ...filtro, [event.target.name]: "" });
      }
    }
  };

  const handleClick = () => {
    setFiltro({
      ...filtro,
      token: getSession().token,
      ruc: getSession().ruc,
      limit: 100,
      offset: 0,
      orden: "fecha",
      tipOrden: "desc",
    });
  };

  const [page, setPage] = useState(1);
  const [bloque, setBloque] = useState(1);
  const [totPages, setTotPages] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (bloque !== Math.ceil(newPage / 10)) {
      setBloque(Math.ceil(newPage / 10));
      setFiltro({
        ...filtro,
        offset: 100 * Math.ceil(newPage / 10) - 100,
        limit: 100,
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = filtro.orden === property && filtro.tipOrden === "asc";
    setFiltro({
      ...filtro,
      orden: property,
      tipOrden: isAsc ? "desc" : "asc",
    });
  };
  const [consulta, setConsulta] = useState(false);

  useEffect(() => {
    (async () => {
      const usu = getSession();
      const docs = await listarDocs(filtro);
      if (docs !== undefined && docs !== null) {
        let dRow = await calcula(docs.ls);
        setRows(dRow);
        setTotPages(Math.ceil(docs.registros / 10));
        setRegistros(docs.registros);
      }
    })();
  }, [filtro, consulta]);

  return (
    <Paper sx={{ width: "100%", maxWidth: "100%" }} className="header">
      <Grid container>
        <Grid size={4} style={stylePad}>
          <TextField
            id="buscar0"
            className="color"
            name="cdc"
            type="text"
            label="Buscar CDC..."
            variant="filled"
            fullWidth
            value={filtro.cdc}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={3} style={stylePad}>
          <TextField
            id="buscar"
            className="color"
            name="nombre"
            type="text"
            label="Buscar cliente..."
            variant="filled"
            fullWidth
            value={filtro.nombre}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={3} style={stylePad}>
          <TextField
            id="buscar"
            className="color"
            name="lote"
            type="text"
            label="Buscar Lote..."
            variant="filled"
            fullWidth
            value={filtro.lote}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={2} style={stylePad}>
          <FormControl className="color" variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-filled-label">
              Comprobante
            </InputLabel>
            <Select
              id="tipcmp"
              name="tipcmp"
              value={filtro.tipcmp === "" ? "Todos" : filtro.tipcmp}
              onChange={handleChange}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Factura electrónica">Factura</MenuItem>
              <MenuItem value="Nota de crédito electrónica">
                Nota de crédito
              </MenuItem>
              <MenuItem value="Autofactura electrónica">Autofactura</MenuItem>
              <MenuItem value="Nota de débito electrónica">
                Nota de débito
              </MenuItem>
              <MenuItem value="Nota de remisión electrónica">
                Nota de remisión
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid size={2} style={stylePad}>
          <TextField
            id="suc"
            className="color"
            name="suc"
            type="text"
            label="Establecimiento..."
            variant="filled"
            fullWidth
            value={filtro.suc}
            maxLength={3}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={2} style={stylePad}>
          <TextField
            id="exp"
            className="color"
            name="exp"
            type="text"
            label="Punto de Expedicion..."
            variant="filled"
            fullWidth
            value={filtro.exp}
            maxLength={3}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={2} style={stylePad}>
          <TextField
            id="buscar2"
            className="color"
            name="nrocmp"
            type="text"
            label="Numero..."
            variant="filled"
            fullWidth
            value={filtro.nrocmp}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={2} style={stylePad}>
          <TextField
            id="buscar4"
            className="color"
            name="fecha"
            type="date"
            variant="filled"
            fullWidth
            value={filtro.fecha}
            style={{ marginLeft: "5px" }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid size={2} style={stylePad}>
          <FormControl className="color" variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-filled-label">Estado</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="condicion"
              value={filtro.condicion === "" ? "Todos" : filtro.condicion}
              onChange={handleChange}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Pendiente">Pendientes</MenuItem>
              <MenuItem value="Aprobado">Aprobados</MenuItem>
              <MenuItem value="Rechazado">Rechazados</MenuItem>
              <MenuItem value="Cancelado">Cancelado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid size={1} style={stylePad}>
          <Button variant="outlined" color="primary" onClick={handleClick}>
            <SearchRounded />
          </Button>
        </Grid>
        <Grid size={12}>
          <EnhancedTable
            rows={rows}
            registros={registros}
            handleChangePage={handleChangePage}
            handleRequestSort={handleRequestSort}
            page={page}
            bloque={bloque}
            totPages={totPages}
            filtro={filtro}
            setFiltro={setFiltro}
            setConsulta={setConsulta}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GrillaDoc;

import React, { useEffect, useState } from "react";
import Menu from "../principal/menu/Menu";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Resumen,
  Emisor,
  Receptor,
  ItemsOperac,
  TotSub,
  DocumeAsoci,
  OtroGrup,
  EvenRecep,
} from "./ConsulEvDE";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { toast } from "react-toastify";
import { getItemSession } from "../../apis/Session";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ConsultaDE = () => {
  //const url = "http://10.10.13.171:8080/Factura_Electronica/"

  const [cdc, setCdc] = useState();

  const [eventos, setEventos] = useState({
    dNumTim: "",
    dDVId: "",
    dDesTiDE: "",
    dSisFact: "",
    dFeEmiDE: "",
    dFecFirma: "",
    dVerFor: "",
  });

  const handleChange = (event) => {
    setCdc(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
  };

  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  const [hover, setHover] = useState();

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  const style = {
    width: "80%",
    height: "calc(88vh)",
  };

  const stylePad = {
    padding: "1% 2% 1% 2%",
    margin: "1% 1% 0% 0%",
    // border: '1px solid',
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  const btnSearchStyle = {
    padding: "2% 3% 2% 3%",
    margin: "0% 0% 0% 3%",
    width: "95%",
  };

  return (
    <React.Fragment>
      <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <h1 style={{ textAlign: "right", color: "rgb(212 212 219)" }}>
              Consultar Documento Electronico
            </h1>
            <Paper sx={{ width: "100%", maxWidth: "100%" }} className="header">
              <Grid container>
                <Grid size={12}>
                  <div style={{ ...stylePad, textAlign: "left" }}>
                    <Grid container>
                      <Grid size={6}>
                        <TextField
                          id="outlined-size-small"
                          size="small"
                          fullWidth
                          onChange={handleChange}
                          value={cdc}
                          label="Ingresar CDC"
                        />
                      </Grid>
                      <Grid size={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClick}
                          startIcon={<SearchOutlined />}
                          style={btnSearchStyle}
                        >
                          Buscar
                        </Button>
                      </Grid>
                      <Grid size={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClick}
                          startIcon={<DownloadOutlinedIcon />}
                          style={btnSearchStyle}
                        >
                          Descargar XML
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                {/* <Grid size={12}>
                  <div style={{ ...stylePad, textAlign: "left" }}>
                    <Grid container>           
                      <Grid size={12}>
                        <FormControl sx={{ flexDirection: "row", margin: 2 }}>
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Timbrado N°"
                            variant="outlined"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="N° de DTE"
                            variant="outlined"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Tipo de DTE"
                            variant="outlined"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Tipo de Emision"
                            variant="outlined"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Fecha y Hora de Emision"
                            variant="outlined"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Fecha y Hora de Firma Digital"
                            variant="outlined"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Version XML"
                            variant="outlined"
                            value="150"
                          />
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            label="Serie"
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        overflow="hidden"
                      >
                        <Tabs
                          xs={2}
                          onChange={handleChangeTabs}
                          value={valueTab}
                          orientation="vertical"
                          sx={{
                            textAlign: "left",
                            marginLeft: 0,
                            borderRight: 1,
                            borderColor: "divider",
                          }}
                          aria-label="Vertical tabs"
                          onFollowsFocusselection
                        >
                          <Tab
                            label="Resumen"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{
                              fontSize: 12,
                              fontFamily: "Arial Black",
                            }}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label="Emisor"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{
                              fontSize: 12,
                              fontFamily: "Arial Black",
                            }}
                            {...a11yProps(1)}
                          />
                          <Tab
                            label="Receptor"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                            {...a11yProps(2)}
                          />
                          <Tab
                            label="Items de la operacion"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                            {...a11yProps(3)}
                          />
                          <Tab
                            label="Totales y Subtotales"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                            {...a11yProps(4)}
                          />
                          <Tab
                            label="Documentos Asociados"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                            {...a11yProps(5)}
                          />
                          <Tab
                            label="Otros Grupos"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                            {...a11yProps(6)}
                          />
                          <Tab
                            label="Eventos del Receptor"
                            onMouseOver={handleMouseIn}
                            onMouseOut={handleMouseOut}
                            sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                            {...a11yProps(7)}
                          />
                        </Tabs>
                      </Grid>
                    </Grid>
                  </div>
                </Grid> */}
              </Grid>
            </Paper>
          </div>
        </Box>
      </div>

      {/* <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <Grid container>
              <Grid size={12}>
                <div style={{ ...stylePad, textAlign: "left" }}>
                  <Grid container>
                    <Grid size={6}>
                      <TextField
                        id="outlined-size-small"
                        size="small"
                        onChange={handleChange}
                        value={cdc}
                        label="Ingresar CDC"
                      />
                      <Button
                        variant="contained"
                        component="span"
                        size="small"
                        style={{ margin: 5, marginLeft: 25 }}
                        onClick={{ handleClick }}
                      >
                        Consultar
                      </Button>
                      <label>{cdc}</label>
                      <Grid
                        intem
                        xs={6}
                        style={{ margin: -40, marginLeft: 335 }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<DownloadOutlinedIcon />}
                          style={{ margin: 6, marginLeft: 5 }}
                        />
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<LocalPrintshopOutlinedIcon />}
                          style={{ margin: 6, marginLeft: 5 }}
                        />
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<InsertDriveFileOutlinedIcon />}
                          style={{ margin: 1, marginLeft: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid size={6}>
                      <h1
                        style={{
                          textAlign: "right",
                          color: "rgb(212 212 219)",
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      >
                        Consulta DE
                      </h1>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </div> */}
      {/* <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              width: 1080,
              height: 550,
              margin: -70,
              marginLeft: 32,
            },
          }}
        >
          <Paper>
            <div>
              <Grid container>
                <Grid>
                  <FormControl sx={{ flexDirection: "row", margin: 2 }}>
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Timbrado N°"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="N° de DTE"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Tipo de DTE"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Tipo de Emision"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Fecha y Hora de Emision"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Fecha y Hora de Firma Digital"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Version XML"
                      variant="outlined"
                      value="150"
                    />
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      label="Serie"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid size={20} sx={{ display: "flex" }} overflow="hidden">
                  <Tabs
                    xs={2}
                    onChange={handleChangeTabs}
                    value={valueTab}
                    orientation="vertical"
                    sx={{
                      textAlign: "left",
                      marginLeft: 0,
                      borderRight: 1,
                      borderColor: "divider",
                    }}
                    aria-label="Vertical tabs example"
                    onFollowsFocusselection
                  >
                    <Tab
                      label="Resumen"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Emisor"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Receptor"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(2)}
                    />
                    <Tab
                      label="Items de la operacion"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(3)}
                    />
                    <Tab
                      label="Totales y Subtotales"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(4)}
                    />
                    <Tab
                      label="Documentos Asociados"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(5)}
                    />
                    <Tab
                      label="Otros Grupos"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(6)}
                    />
                    <Tab
                      label="Eventos del Receptor"
                      onMouseOver={handleMouseIn}
                      onMouseOut={handleMouseOut}
                      sx={{ fontSize: 12, fontFamily: "Arial Black" }}
                      {...a11yProps(7)}
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <TabPanel value={valueTab} index={0}>
                <Resumen />
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <Emisor />
              </TabPanel>
              <TabPanel value={valueTab} index={2}>
                <Receptor />
              </TabPanel>
              <TabPanel value={valueTab} index={3}>
                <ItemsOperac />
              </TabPanel>
              <TabPanel value={valueTab} index={4}>
                <TotSub />
              </TabPanel>
              <TabPanel value={valueTab} index={5}>
                <DocumeAsoci />
              </TabPanel>
              <TabPanel value={valueTab} index={6}>
                <OtroGrup />
              </TabPanel>
              <TabPanel value={valueTab} index={7}>
                <EvenRecep />
              </TabPanel>
            </div>
          </Paper>
        </Box>
      </div> */}
    </React.Fragment>
  );
};

export default ConsultaDE;

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import { getItemSession, setSession } from "../../apis/Session";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import "../../css/estilo.css";
import PassInput from "../login/PassInput";
import { toast } from "react-toastify";
import { getSession } from "../../apis/Session";
import { validUsuario, cambiaPass } from "../../apis/api";

const ChangePass = (datos) => {
  const { open, handleClose } = datos;

  const [values, setValues] = React.useState({
    ruc: getItemSession("ruc"),
    nombre: getSession().nombre,
    password: "",
    passwordNew: "",
    passwordNewConf: "",
    showPassword: false,
    role: getSession().role,
  });

  React.useEffect(() => limpiar(), [open]);

  const [errores, setErrores] = React.useState({
    password: "",
    passwordNew: "",
    passwordNewConf: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      ruc: getItemSession("ruc"),
      nombre: getSession().nombre,
      role: getSession().role,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const limpiar = () => {
    setErrores({
      password: "",
      passwordNew: "",
      passwordNewConf: "",
    });
    setValues({
      ruc: getItemSession("ruc"),
      nombre: getSession().nombre,
      password: "",
      passwordNew: "",
      passwordNewConf: "",
      showPassword: false,
      role: getSession().role,
    });
  };

  const handleConfirm = async () => {
    if (values.password && values.passwordNew && values.passwordNewConf) {
      let resp = await validUsuario(values);
      if (resp.estado === false) {
        toast.error("CONTRASEÑA DE ACCESO INCORRECTA", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (values.passwordNew !== values.passwordNewConf) {
          toast.error("LAS CONTRASEÑAS INGRESADAS NO COINCIDEN", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let cambia = await cambiaPass(values);
          if (cambia) {
            toast.success("CONTRASEÑA CAMBIADA CORRECTAMENTE", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
            setSession(resp);
          }
        }
      }
    } else {
      toast.error("POR FAVOR COMPLETE LOS CAMPOS REQUERIDOS", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cambio de Contraseña de Acceso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Con esta opción se cambia tu password de acceso a esta plataforma
            web
          </DialogContentText>
          <form>
            <Grid container spacing={2}>
              <Grid size={12}>
                <TextField
                  id="nombre"
                  name="nombre"
                  style={{ marginTop: "1.5rem" }}
                  // label="Nombre"
                  fullWidth
                  variant="standard"
                  value={getSession().nombre}
                  disabled={true}
                />
              </Grid>
              <Grid size={12}>
                <PassInput
                  name="password"
                  handleChange={(e) => handleChange(e)}
                  values={values.password}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  helperText={errores.password}
                  error={errores.password === "" ? false : true}
                  adornment="Password Actual"
                />
              </Grid>
              <Grid size={12}>
                <PassInput
                  name="passwordNew"
                  handleChange={(e) => handleChange(e)}
                  values={values.passwordNew}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  helperText={errores.password}
                  error={errores.password === "" ? false : true}
                  adornment="Nuevo Password"
                />
              </Grid>
              <Grid size={12}>
                <PassInput
                  name="passwordNewConf"
                  handleChange={handleChange}
                  values={values.passwordNewConf}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  helperText={errores.password}
                  error={errores.password === "" ? false : true}
                  adornment="Confirmar Nuevo Password"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={handleConfirm}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangePass;

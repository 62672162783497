// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-image: linear-gradient(-45deg, #039BE5 0%, #1f4253 33%, #00BCD4 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}
body{
  font-family: Geneva, Tahoma, sans-serif, monospace
}
`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gFAAgF;EAChF,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,gBAAgB;AAClB;AACA;EACE;AACF","sourcesContent":[".App {\n  text-align: center;\n  background-image: linear-gradient(-45deg, #039BE5 0%, #1f4253 33%, #00BCD4 100%);\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  min-height: 100%;\n}\nbody{\n  font-family: Geneva, Tahoma, sans-serif, monospace\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Img from "./Img";

const PanelKude = (props) => {
  const styleM = {
    marginBottom: "10px",
  };

  const { configuracion, setConfiguracion, selected, setSelected } = props;

  const handleChange = (e) => {
    switch (e.target.name) {
      case "kude":
        setSelected(e.target.value);
        setConfiguracion({
          ...configuracion,
          kude: e.target.value,
        });
        break;
      case "decimales":
        setConfiguracion({
          ...configuracion,
          decimales: e.target.value,
        });
        break;
      default:
        setConfiguracion({
          ...configuracion,
          [e.target.name]: e.target.value,
        });
    }
  };

  const stylePad = {
    padding: "2% 2% 1% 2%",
    margin: "1% 1% 0% 0%",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid size={12}>
          <div style={{ ...stylePad }}>
            <TextField
              id="decimales"
              name="decimales"
              label="Decimales"
              variant="outlined"
              maxLength={1}
              size="small"
              style={styleM}
              onChange={handleChange}
              value={configuracion.decimales}
            />
          </div>
        </Grid>
        <Grid size={12}>
          <div style={{ ...stylePad }}>
            <SimpleBar forceVisible="false" style={{ maxHeight: 510 }}>
              <div>
                <Grid sx={{ height: 340, width: 350 }}>
                  <label className="cinta">
                    <input
                      type="radio"
                      name="kude"
                      value="2"
                      checked={selected === "2"}
                      onChange={handleChange}
                    />
                    <Img imagen="cinta.png" />
                  </label>
                </Grid>
              </div>
              <div style={{ margin: 350, marginTop: -340 }}>
                <Grid sx={{ height: 340, width: 350 }}>
                  <label className="cintaRes">
                    <input
                      type="radio"
                      name="kude"
                      value="3"
                      checked={selected === "3"}
                      onChange={handleChange}
                    />
                    <Img imagen="cintaRes.png" />
                  </label>
                </Grid>
              </div>
              <div style={{ margin: 700, marginTop: -690 }}>
                <Grid sx={{ height: 500, width: 460 }}>
                  <label className="facEsta">
                    <input
                      type="radio"
                      name="kude"
                      value="1"
                      checked={selected === "1"}
                      onChange={handleChange}
                    />
                    <Img imagen="facEsta.png" />
                  </label>
                </Grid>
              </div>
            </SimpleBar>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PanelKude;

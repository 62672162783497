import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const BorraUsr = (datos) => {
  const { open, handleClose, handleConfirm, user } = datos;

  // const handleConfirm = async () => {
  //   await deleteUserLimitado(user);
  //   handleClose();
  // };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Borrar Usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Desea eliminar el usuario ${user.nombre}?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BorraUsr;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { EmailOutlined, PersonPin } from "@mui/icons-material";
import { AssessmentOutlined, UploadFileOutlined } from "@mui/icons-material";
import Menuitem from "./Menuitem";
import { clearSession } from "../../../apis/Session";
import { SessionContext } from "../../../hook/SessionContext";
import { getItemSession } from "../../../apis/Session";

const Menulist = (server) => {
  const navigate = useNavigate();
  const { updSession } = useContext(SessionContext);

  const cerrarSesion = () => {
    clearSession();
    updSession("");
    navigate("../");
  };

  const documentos = () => {
    navigate("../menu");
  };

  const reportes = () => {
    navigate("../reportes");
  };

  const mails = () => {
    navigate("../mails");
  };

  const configuracion = () => {
    navigate("../configuracion");
  };

  const certificado = () => {
    navigate("../certificado");
  };

  const pruebas = () => {
    navigate("../pruebas");
  };

  ///////menuitems

  return (
    <>
      {getItemSession("role") === "Read" ? (
        <MenuList>
          <Menuitem text={`Hola ${getItemSession("nombre").split(" ")[0]}!`}>
            <PersonPin fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={documentos} text="Documentos">
            <ArticleOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={reportes} text="Reportes">
            <AssessmentOutlined fontSize="small" />
          </Menuitem>
          {/* <Menuitem handleClick={pruebas} text="Etapa de Pruebas SIFEN">
            <BugReportOutlined fontSize="small" />
          </Menuitem> */}
          <Menuitem handleClick={cerrarSesion} text="Cerrar Sesión">
            <LogoutOutlinedIcon fontSize="small" />
          </Menuitem>
        </MenuList>
      ) : (
        <MenuList>
          {/* <Menuitem text={`Hola ${getItemSession("nombre").split(" ")[0]}!`}>
            <PersonPin fontSize="small" />
          </Menuitem> */}
          <Menuitem handleClick={documentos} text="Documentos">
            <ArticleOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={reportes} text="Reportes">
            <AssessmentOutlined fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={mails} text="Envio de Correos">
            <EmailOutlined fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={configuracion} text="Configuracion">
            <SettingsOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem text="Certificado" handleClick={certificado}>
            <UploadFileOutlined fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={cerrarSesion} text="Cerrar Sesión">
            <LogoutOutlinedIcon fontSize="small" />
          </Menuitem>
        </MenuList>
      )}
    </>
  );
};

export default Menulist;

import React, { useEffect, useState } from "react";
import Menu from "../principal/menu/Menu";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid2";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  Typography,
  InputLabel,
  Box,
  Paper,
  Container,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { establecimiento, expedicion } from "../../apis/values";
import { getSession } from "../../apis/Session";
import { dataCorreo, correoMasivo, errorMail } from "../../apis/api";
import Errores from "./Errores";

const Mails = () => {
  const [filtro, setFiltro] = useState({
    token: getSession().token,
    ruc: getSession().ruc,
    cdc: "",
    lote: "",
    fecha: "",
    fecini: "",
    fecfin: "",
    envmail: "",
    nombre: "",
    suc: "001",
    exp: "001",
    nrocmp: "",
    tipcmp: "",
    condicion: "",
    limit: 100,
    offset: 0,
    orden: "fecha",
    tipOrden: "desc",
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState([]);
  const [page, setPage] = useState(1);
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    marginLeft: "20%",
    width: "40%",
    height: "calc(88vh)",
  };

  const handleChange = (event) => {
    setFiltro({ ...filtro, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    if (filtro.fecha === "" && filtro.lote === "") {
      return toast("Debe filtrar por fecha o por número de lote ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(true);
    const res = await dataCorreo(filtro);
    const promises = res.map((element) => {
      return correoMasivo(element);
    });
    await Promise.all(promises);
    console.log("Todas las llamadas han terminado");
    setLoading(false);
    return toast.success("Proceso concluido ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleClickVerif = async () => {
    if (filtro.fecha === "") {
      return toast("La fecha no puede estar vacía", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);
      const res = await errorMail(filtro.fecha, filtro.ruc);
      setError(res);
      if (res.length > 0) {
        setLoading(false);
        setOpen(true);
        setPage(0);
      } else {
        setLoading(false);
        return toast("Sin Errores constatados", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <h1 style={{ textAlign: "right", color: "rgb(212 212 219)" }}>
              Firma Digital
            </h1>
            <Paper sx={{ width: "100%", maxWidth: "100%" }} className="header">
              <Grid container>
                {loading ? (
                  <>
                    <Grid size={12} style={{ padding: "15%" }}>
                      <CircularProgress
                        style={{ width: "120px", height: "120px" }}
                        // disableShrink
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid size={12}>
                    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                      <Paper
                        variant="outlined"
                        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                      >
                        <Typography
                          component="h1"
                          variant="h4"
                          align="center"
                          style={{ marginBottom: "30px" }}
                        >
                          Envío de Correos
                        </Typography>
                        <React.Fragment>
                          <Grid container>
                            <Grid
                              size={8}
                              style={{ marginBottom: "calc(2vh)" }}
                            >
                              <TextField
                                id="buscar"
                                className="color"
                                name="lote"
                                type="text"
                                label="Por Lote"
                                variant="standard"
                                fullWidth
                                value={filtro.lote}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid
                              size={8}
                              style={{ marginBottom: "calc(2vh)" }}
                            >
                              <TextField
                                fullWidth
                                label="Por Fecha"
                                id="fecha"
                                name="fecha"
                                value={filtro.fecha}
                                type="date"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start"></InputAdornment>
                                  ),
                                }}
                                variant="standard"
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid size={6}>
                              <FormControl
                                fullWidth
                                variant="standard"
                                sx={{ m: 1, minWidth: 120 }}
                              >
                                <FormControl sx={{ width: "90%" }} size="small">
                                  <InputLabel id="suc001">Sucursal</InputLabel>
                                  <Select
                                    className="form-control form-control-sm"
                                    labelId="demo-select-small"
                                    id="suc"
                                    name="suc"
                                    label="Sucursal"
                                    //   defaultValue={sucs}
                                    value={filtro.suc}
                                    onChange={handleChange}
                                  >
                                    {establecimiento.map((item) => (
                                      <MenuItem
                                        key={item.key}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </FormControl>
                            </Grid>
                            <Grid size={6}>
                              <FormControl
                                fullWidth
                                variant="standard"
                                sx={{ m: 1, minWidth: 120 }}
                              >
                                <FormControl sx={{ width: "90%" }} size="small">
                                  <InputLabel id="expe001">
                                    Punto Expedición
                                  </InputLabel>
                                  <Select
                                    className="form-control form-control-sm"
                                    labelId="demo-select-small"
                                    id="exp"
                                    name="exp"
                                    label="Punto Expedición"
                                    //   defaultValue={exps}
                                    value={filtro.exp}
                                    onChange={handleChange}
                                  >
                                    {expedicion.map((item) => (
                                      <MenuItem
                                        key={item.key}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </FormControl>
                            </Grid>
                            <Grid size={12}></Grid>
                            <Grid size={12}>
                              <React.Fragment>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={handleClickVerif}
                                    sx={{ mt: 3, ml: 1 }}
                                  >
                                    Verificar Envíos
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    sx={{ mt: 3, ml: 1 }}
                                  >
                                    Enviar
                                  </Button>
                                </Box>
                              </React.Fragment>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      </Paper>
                    </Container>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </div>
        </Box>
      </div>
      <Errores
        open={open}
        handleClose={handleClose}
        error={error}
        page={page}
        setPage={setPage}
      />
    </React.Fragment>
  );
};

export default Mails;

import React from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LockIcon from "@mui/icons-material/LockOutlined";
import { Box } from "@mui/system";
import { FormHelperText } from "@mui/material";

const PassInput = ({
  handleChange,
  values,
  handleClickShowPassword,
  handleMouseDownPassword,
  helperText,
  error,
  keyUp,
  adornment,
  name,
  disabled,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <LockIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
      <FormControl error={error} fullWidth variant="standard">
        <InputLabel htmlFor="standard-adornment-password">
          {adornment ? adornment : "Password"}
        </InputLabel>
        <Input
          id="standard-adornment-password"
          name={name ? name : undefined}
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="component-error-text"
          onKeyUp={keyUp}
          disabled={disabled}
        />
        {error ? (
          <FormHelperText id="component-error-text">
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default PassInput;

import React, { useEffect, useState } from "react";
import Menu from "../principal/menu/Menu";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid2";
import {
  TextField,
  Divider,
  Card,
  CardActions,
  Typography,
  Button,
  Box,
  Paper,
  Container,
} from "@mui/material";
import {
  subirCert,
  getConfiguracion,
  getVencimiento,
  subirDatosCert,
} from "../../apis/api";

const Certificado = () => {
  const style = {
    marginLeft: "20%",
    width: "40%",
    height: "calc(88vh)",
  };
  const styleM = {
    marginBottom: "10px",
  };

  const [configuracion, setConfiguracion] = useState(confIni);

  const modifConf = async (res) => {
    configuracion.aliasCert = res.data.alias;
    configuracion.usuCert = res.data.name;
    configuracion.certificado = "si";
    configuracion.vencimiento = res.data.vencimiento;
  };

  const handleChangeInput = async (evt) => {
    let file = evt.target.files[0];
    if (
      configuracion.passCert === "" ||
      configuracion.passCert === undefined ||
      configuracion.passCert === null
    ) {
      toast("DEBE CARGAR LA CONTRASEÑA", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let res = await subirCert(configuracion, file);
      if (res.codigo === "200") {
        setConfiguracion({
          ...configuracion,
          aliasCert: res.data.alias,
          usuCert: res.data.name,
          certificado: "si",
          vencimiento: res.data.vencimiento,
        });
        await modifConf(res);
        await subirDatosCert(configuracion);
        toast("Certificado subido correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setConfiguracion({
          ...configuracion,
          aliasCert: "",
          usuCert: "",
          certificado: "no",
          vencimiento: "",
        });
        toast(res.respuesta, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // setConfiguracion({
      //   ...configuracion,
      //   aliasCert: res.data.alias,
      //   usuCert: res.data.name,
      //   certificado: "si",
      //   vencimiento: res.data.vencimiento,
      // });
      // await subirDatosCert(configuracion);
    }
  };

  const btnfile = () => {
    const file = document.querySelector("#certificado");
    file.value = "";
  };

  const handleChange = (event) => {
    if (event.target.name === "passCert") {
      setConfiguracion({
        ...configuracion,
        [event.target.name]: event.target.value,
        passCertOld:
          event.target.value === configuracion.passCertOld
            ? event.target.value
            : configuracion.passCertOld,
      });
    } else {
      setConfiguracion({
        ...configuracion,
        [event.target.name]: event.target.value,
      });
    }
  };

  useEffect(() => {
    (async () => {
      let config = await getConfiguracion();
      if (
        config.data.vencimiento === null ||
        config.data.vencimiento === undefined
      ) {
        let vnc = await getVencimiento();
        config.data.vencimiento =
          vnc === undefined || vnc.length === 0
            ? "1900-01-01"
            : vnc[0].fechaproceso;
      }
      setConfiguracion(config.data);
    })();
  }, []);

  return (
    <React.Fragment>
      <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <h1 style={{ textAlign: "right", color: "rgb(212 212 219)" }}>
              Firma Digital
            </h1>
            <Paper sx={{ width: "100%", maxWidth: "100%" }} className="header">
              <Grid container>
                <Grid size={12}>
                  <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                    <Paper
                      variant="outlined"
                      sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                    >
                      <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        style={{ marginBottom: "30px" }}
                      >
                        Subir Certificado
                      </Typography>
                      <React.Fragment>
                        <Grid container>
                          <Grid size={10}>
                            {/* <h3 style={{ marginBottom: "5px" }}>Certificado</h3> */}
                            <Divider style={styleM} />
                            <div>
                              <TextField
                                id="passCert"
                                name="passCert"
                                label="Contraseña"
                                type="password"
                                variant="outlined"
                                size="small"
                                fullWidth
                                style={styleM}
                                onChange={handleChange}
                                value={configuracion.passCert}
                              />
                            </div>
                            <div>
                              <TextField
                                id="passCertOld"
                                name="passCertOld"
                                type="password"
                                variant="outlined"
                                size="small"
                                fullWidth
                                style={{ display: "none" }}
                                value={configuracion.passCertOld}
                              />
                            </div>
                            <Grid size={10}>
                              <TextField
                                id="usuCert"
                                name="usuCert"
                                label="Usuario"
                                variant="outlined"
                                size="small"
                                style={styleM}
                                fullWidth
                                onChange={handleChange}
                                value={configuracion.usuCert}
                                disabled
                              />
                              <TextField
                                id="aliasCert"
                                name="aliasCert"
                                label="Alias"
                                variant="outlined"
                                size="small"
                                style={styleM}
                                fullWidth
                                onChange={handleChange}
                                value={configuracion.aliasCert}
                                disabled
                              />
                            </Grid>
                            <Grid size={6}>
                              <TextField
                                id="vencimiento"
                                name="vencimiento"
                                size="small"
                                variant="outlined"
                                style={styleM}
                                type="date"
                                fullWidth
                                onChange={handleChange}
                                value={
                                  configuracion.vencimiento === null ||
                                  configuracion.vencimiento === undefined
                                    ? "1900-01-01"
                                    : configuracion.vencimiento
                                }
                                disabled
                              />
                            </Grid>
                            <Grid>
                              <Card>
                                <CardActions lg={{ textAlign: "right" }}>
                                  {configuracion.certificado === "si" ? (
                                    <div>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        onClick={() => {
                                          setConfiguracion({
                                            ...configuracion,
                                            certificado: "no",
                                          });
                                        }}
                                      >
                                        Remover Certificado
                                      </Button>
                                      <label
                                        style={{
                                          marginLeft: 10,
                                          color: "gray",
                                        }}
                                      >
                                        CERTIFICADO ADJUNTADO
                                      </label>
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        accept=".p12"
                                        style={{ display: "none" }}
                                        id="certificado"
                                        type="file"
                                        onChange={handleChangeInput}
                                        onClick={() => btnfile()}
                                      />
                                      <label htmlFor="certificado">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          size="small"
                                          color="success"
                                        >
                                          Adjuntar Certificado
                                        </Button>
                                      </label>
                                    </div>
                                  )}
                                </CardActions>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    </Paper>
                  </Container>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Certificado;

const confIni = {
  ruc: "",
  certificado: "no",
  usuCert: "",
  passCert: "",
  passCertOld: "",
  aliasCert: "",
  vencimiento: "",
};

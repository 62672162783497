// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    margin: 10px 10px;
    border-radius: 10px !important;
    height: 98%;
    /* background: linear-gradient(195deg, rgb(212 212 219), rgb(178 181 181)); */
    background-color: white;
}

.texto{
    text-align: right;
    width: 100%;
    font-size: 25px;
    padding: 5px 0px;
}
.textoPlna{
    text-align: left;
    width: 100%;
    font-size: 20px;
    padding: 15px 5px;
}
.pdr20{
    padding-right: 20px;
}

.color label{
    color:#1c6275
}`, "",{"version":3,"sources":["webpack://./src/css/header.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,WAAW;IACX,6EAA6E;IAC7E,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;AACJ","sourcesContent":[".header{\n    margin: 10px 10px;\n    border-radius: 10px !important;\n    height: 98%;\n    /* background: linear-gradient(195deg, rgb(212 212 219), rgb(178 181 181)); */\n    background-color: white;\n}\n\n.texto{\n    text-align: right;\n    width: 100%;\n    font-size: 25px;\n    padding: 5px 0px;\n}\n.textoPlna{\n    text-align: left;\n    width: 100%;\n    font-size: 20px;\n    padding: 15px 5px;\n}\n.pdr20{\n    padding-right: 20px;\n}\n\n.color label{\n    color:#1c6275\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

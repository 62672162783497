import React from "react";
import Menu from "../principal/menu/Menu";
import { Box, Button, TextField, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";

const ConsultaLote = () => {
  const style = {
    width: "80%",
    height: "calc(88vh)",
  };

  const stylePad = {
    padding: "1% 2% 1% 2%",
    margin: "1% 1% 0% 0%",
    // border: '1px solid',
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  return (
    <React.Fragment>
      <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <Grid container>
              <Grid size={12}>
                <div style={{ ...stylePad, textAlign: "left" }}>
                  <Grid container>
                    <Grid size={6}>
                      <TextField size="small" label="Ingresar CDC" />
                      <Button
                        variant="contained"
                        component="span"
                        size="medium"
                        style={{ margin: "3px", marginLeft: "25px" }}
                      >
                        Consultar
                      </Button>
                    </Grid>
                    <Grid size={6}>
                      <h1
                        style={{
                          textAlign: "right",
                          color: "rgb(212 212 219)",
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      >
                        Consulta Lote
                      </h1>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
      <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              width: 1080,
              height: 550,
              margin: -70,
              marginLeft: 32,
            },
          }}
        >
          <Paper></Paper>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default ConsultaLote;

import React, { Fragment, useEffect, useState, useLayoutEffect } from "react";
import Menu from "../principal/menu/Menu";
import { getItemSession, MainUrl } from "../../apis/Session";
import { nroFac } from "../../Datos/Datos";
import { toast } from "react-toastify";
import VerDocs from "./VerDocs";
import WebService from "./WebService";
import {
  dataDE_,
  dataAU_,
  inuparam,
  idesconoceparam,
  notifyparam,
} from "./data";
import {
  Box,
  Button,
  Paper,
  TextField,
  Alert,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import moment from "moment";
import {
  getDocsPrueba,
  getConfigPrueba,
  grabaConfigPrueba,
} from "../../apis/api";

const style = {
  width: "80%",
  height: "calc(100vh)",
};
const stylePad = {
  padding: "0% 1% 0%",
  textAlign: "left",
};
const stylePad2 = {
  padding: "1%",
  textAlign: "right",
};
const styleM = {
  marginBottom: "10px",
  marginLeft: "10px",
};

const Pruebas = (props) => {
  var dataDE = { ...dataDE_ };
  var dataDEA = { ...dataDE_ };
  var dataAU = { ...dataAU_ };
  var dataAUA = { ...dataAU_ };
  var disconfparam = { ...idesconoceparam };
  var notificadoparam = { ...notifyparam };
  const [loading, setLoading] = useState(false);
  const [lis_Test, setlis_Test] = useState([]);
  const [lis_FE_Sinc_Aprob, setlis_FE_Sinc_Aprob] = useState([]);
  const [lis_NC_Sinc_Aprob, setlis_NC_Sinc_Aprob] = useState([]);
  const [lis_ND_Sinc_Aprob, setlis_ND_Sinc_Aprob] = useState([]);
  const [lis_AU_Sinc_Aprob, setlis_AU_Sinc_Aprob] = useState([]);
  const [lis_NR_Sinc_Aprob, setlis_NR_Sinc_Aprob] = useState([]);
  const [lis_FE_Sinc_Recha, setlis_FE_Sinc_Recha] = useState([]);
  const [lis_NC_Sinc_Recha, setlis_NC_Sinc_Recha] = useState([]);
  const [lis_ND_Sinc_Recha, setlis_ND_Sinc_Recha] = useState([]);
  const [lis_AU_Sinc_Recha, setlis_AU_Sinc_Recha] = useState([]);
  const [lis_NR_Sinc_Recha, setlis_NR_Sinc_Recha] = useState([]);
  const [lis_FE_ASinc_Aprob, setlis_FE_ASinc_Aprob] = useState([]);
  const [lis_NC_ASinc_Aprob, setlis_NC_ASinc_Aprob] = useState([]);
  const [lis_ND_ASinc_Aprob, setlis_ND_ASinc_Aprob] = useState([]);
  const [lis_AU_ASinc_Aprob, setlis_AU_ASinc_Aprob] = useState([]);
  const [lis_NR_ASinc_Aprob, setlis_NR_ASinc_Aprob] = useState([]);
  const [lis_FE_ASinc_Recha, setlis_FE_ASinc_Recha] = useState([]);
  const [lis_NC_ASinc_Recha, setlis_NC_ASinc_Recha] = useState([]);
  const [lis_ND_ASinc_Recha, setlis_ND_ASinc_Recha] = useState([]);
  const [lis_AU_ASinc_Recha, setlis_AU_ASinc_Recha] = useState([]);
  const [lis_NR_ASinc_Recha, setlis_NR_ASinc_Recha] = useState([]);
  const [lis_FE_Cancelacion, setlis_FE_Cancelacion] = useState([]);
  const [lis_NC_Cancelacion, setlis_NC_Cancelacion] = useState([]);
  const [lis_ND_Cancelacion, setlis_ND_Cancelacion] = useState([]);
  const [lis_AU_Cancelacion, setlis_AU_Cancelacion] = useState([]);
  const [lis_NR_Cancelacion, setlis_NR_Cancelacion] = useState([]);
  const [lis_FE_Inutilizacion, setlis_FE_Inutilizacion] = useState([]);
  const [lis_NC_Inutilizacion, setlis_NC_Inutilizacion] = useState([]);
  const [lis_ND_Inutilizacion, setlis_ND_Inutilizacion] = useState([]);
  const [lis_AU_Inutilizacion, setlis_AU_Inutilizacion] = useState([]);
  const [lis_NR_Inutilizacion, setlis_NR_Inutilizacion] = useState([]);
  const [lis_Conformidad, setlis_Conformidad] = useState([]);
  const [lis_Disconformidad, setlis_Disconformidad] = useState([]);
  const [lis_Desconocimiento, setlis_Desconocimiento] = useState([]);
  const [lis_Notificacion, setlis_Notificacion] = useState([]);
  const [lis_Ajuste, setlis_Ajuste] = useState([]);
  const [lis_FE_Consulta, setlis_FE_Consulta] = useState([]);
  const [lis_NC_Consulta, setlis_NC_Consulta] = useState([]);
  const [lis_ND_Consulta, setlis_ND_Consulta] = useState([]);
  const [lis_AU_Consulta, setlis_AU_Consulta] = useState([]);
  const [lis_NR_Consulta, setlis_NR_Consulta] = useState([]);

  /*********************************ACCIONES***********************************/

  let dataAsync = [];

  const handleSendWS = async (iTiDE, tipoWs) => {
    setLoading(true);
    let DesTiDE;
    switch (iTiDE) {
      case 1:
        DesTiDE = "Factura electrónica";
        break;
      case 4:
        DesTiDE = "Autofactura electrónica";
        break;
      case 5:
        DesTiDE = "Nota de crédito electrónica";
        break;
      case 6:
        DesTiDE = "Nota de débito electrónica";
        break;
      case 7:
        DesTiDE = "Nota de remisión electrónica";
        break;
    }
    let cnf = await getConfigPrueba();
    console.log("cnf", cnf);
    let pasa = 0;
    let termina = false;
    let respuesta = false;
    if (cnf.GEmis !== "") {
      switch (tipoWs) {
        case "Sincrono-Aprobado":
          for (let i = 0; i < 11; i++) {
            dataDE.gTimb.iTiDE = iTiDE;
            dataDE.gTimb.dDesTiDE = DesTiDE;
            dataDE.gDatGralOpe.dFeEmiDE = moment()
              .subtract(10, "minutes")
              .format("YYYY-MM-DDTHH:MM:59");
            dataDE.gTimb.dNumTim = cnf.ConfTimb.timbrado;
            dataDE.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
            dataDE.gTimb.dEst = cnf.ConfTimb.establecimiento;
            dataDE.gTimb.dPunExp = cnf.ConfTimb.expedicion;
            dataDE.gTimb.dNumDoc = nroFac(
              `${parseFloat(cnf.ConfTimb.numeroini) + parseFloat(i)}`
            );
            dataDE.gDatGralOpe.gEmis = cnf.GEmis;

            switch (iTiDE) {
              case 4:
                dataAU.gDatGralOpe.dFeEmiDE = moment()
                  .subtract(10, "minutes")
                  .format("YYYY-MM-DDTHH:MM:59");
                dataAU.gTimb.dNumTim = cnf.ConfTimb.timbrado;
                dataAU.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
                dataAU.gTimb.dEst = cnf.ConfTimb.establecimiento;
                dataAU.gTimb.dPunExp = cnf.ConfTimb.expedicion;
                dataAU.gDatGralOpe.gDatRec.dRucRec = cnf.GEmis.dRucEm;
                dataAU.gDatGralOpe.gDatRec.dDVRec = cnf.GEmis.dDVEmi;
                dataAU.gDatGralOpe.gDatRec.dNomRec = cnf.GEmis.dNomEmi;
                dataAU.gTimb.dNumDoc = nroFac(
                  `${parseFloat(cnf.ConfTimb.numeroini) + parseFloat(i)}`
                );
                dataAU.gDatGralOpe.gEmis = cnf.GEmis;
                break;
              case 5:
                dataDE.gDtipDE.gCamNCDE.iMotEmi = 2;
                dataDE.gDtipDE.gCamNCDE.dDesMotEmi = "Devolución";
                dataDE.gCamDEAsoc.iTipDocAso = 1;
                dataDE.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                dataDE.gCamDEAsoc.dCdCDERef =
                  lis_FE_Sinc_Aprob && lis_FE_Sinc_Aprob.length > 0
                    ? lis_FE_Sinc_Aprob[i].cdc
                    : lis_Test[i].CDC;
                break;
              case 6:
                dataDE.gDtipDE.gCamNCDE.iMotEmi = 3;
                dataDE.gDtipDE.gCamNCDE.dDesMotEmi = "Descuento";
                dataDE.gCamDEAsoc.iTipDocAso = 1;
                dataDE.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                dataDE.gCamDEAsoc.dCdCDERef =
                  lis_FE_Sinc_Aprob && lis_FE_Sinc_Aprob.length > 0
                    ? lis_FE_Sinc_Aprob[i].cdc
                    : lis_Test[i].CDC;
                break;
              default:
            }
            console.log("dataDE", dataDE);
            // console.log("dataAU", dataAU);
            const resp = await fetch(
              `${MainUrl}rest/enviarDE_Test?ruc=${getItemSession(
                "ruc"
              )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(iTiDE === 4 ? dataAU : dataDE),
              }
            );
            if (resp.status === 200) {
              pasa += i;
              if (i === 9) {
                termina = true;
                setLoading(false);
                setConfigPrueba(cnf.ConfTimb);
              }
            }
          }
          break;
        case "Sincrono-Rechazado":
          for (let i = 0; i < 6; i++) {
            dataDE.gTimb.iTiDE = iTiDE;
            dataDE.gTimb.dDesTiDE = DesTiDE;
            dataDE.gDatGralOpe.dFeEmiDE = moment()
              .subtract(5, "minutes")
              .format("YYYY-MM-DDTHH:MM:59");
            dataDE.gTimb.dNumTim = cnf.ConfTimb.timbrado;
            dataDE.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
            dataDE.gTimb.dEst = cnf.ConfTimb.establecimiento;
            dataDE.gTimb.dPunExp = cnf.ConfTimb.expedicion;
            dataDE.gTimb.dNumDoc = nroFac(
              `${parseFloat(cnf.ConfTimb.numeroini) + parseFloat(i)}`
            );
            dataDE.gDatGralOpe.gEmis = cnf.GEmis;
            dataAU.gDatGralOpe.dFeEmiDE = moment()
              .subtract(10, "minutes")
              .format("YYYY-MM-DDTHH:MM:59");
            dataAU.gTimb.dNumTim = cnf.ConfTimb.timbrado;
            dataAU.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
            dataAU.gTimb.dEst = cnf.ConfTimb.establecimiento;
            dataAU.gTimb.dPunExp = cnf.ConfTimb.expedicion;
            dataAU.gTimb.dNumDoc = nroFac(
              `${parseFloat(cnf.ConfTimb.numeroini) + parseFloat(i)}`
            );
            switch (i) {
              case 0:
                dataDE.gTotSub.dTotGralOpe = 5000000.0;
                dataDE.gDtipDE.gCamItem[0].gValorItem.dTotBruOpeItem = 250000.0;
                dataDE.gDtipDE.gCamItem[0].dCodInt = "000001";
                dataDE.gDtipDE.gCamCond.iCondOpe = 2;
                dataDE.gDtipDE.gCamCond.dDCondOpe = "Credito";
                dataDE.gDatGralOpe.gDatRec.dRucRec = "80026041";
                dataDE.gDatGralOpe.gOpeCom.cMoneOpe = "PYG";
                dataDE.gDatGralOpe.gOpeCom.dDesTImp = "IVA – Renta";
                dataDE.gTotSub.dBaseGrav10 = 454545.45454546;
                iTiDE !== 4
                  ? (dataDE.gCamDEAsoc = {
                      iTipDocAso: null,
                      dDesTipDocAso: null,
                      dCdCDERef: null,
                    })
                  : (dataDE.gCamDEAsoc = {
                      iTipDocAso: 3,
                      dDesTipDocAso: "Constancia Electrónica",
                      iTipCons: 1,
                      dDesTipCons: "Constancia de no ser contribuyente",
                      dNumCons: 0,
                      dNumControl: "",
                    });
                break;
              case 1:
                dataDE.gTotSub.dTotGralOpe = 500000.0;
                dataDE.gDtipDE.gCamItem[0].gValorItem.dTotBruOpeItem = 250000.0;
                dataDE.gDtipDE.gCamItem[0].dCodInt = null;
                dataDE.gDtipDE.gCamCond.iCondOpe = 2;
                dataDE.gDtipDE.gCamCond.dDCondOpe = "Credito";
                dataDE.gDatGralOpe.gDatRec.dRucRec = "80026041";
                dataDE.gDatGralOpe.gOpeCom.cMoneOpe = "PYG";
                dataDE.gDatGralOpe.gOpeCom.dDesTImp = "IVA – Renta";
                dataDE.gTotSub.dBaseGrav10 = 454545.45454546;
                // dataDE.gCamDEAsoc.iTipDocAso = null;
                iTiDE !== 4
                  ? (dataDE.gCamDEAsoc = {
                      iTipDocAso: null,
                      dDesTipDocAso: null,
                      dCdCDERef: null,
                    })
                  : (dataDE.gCamDEAsoc = {
                      iTipDocAso: 3,
                      dDesTipDocAso: "Constancia Electrónica",
                      iTipCons: 1,
                      dDesTipCons: "Constancia de no ser contribuyente",
                      dNumCons: 0,
                      dNumControl: "",
                    });
                break;
              case 2:
                dataDE.gTotSub.dTotGralOpe = 500000.0;
                dataDE.gDtipDE.gCamItem[0].gValorItem.dTotBruOpeItem = 250000.0;
                dataDE.gDtipDE.gCamItem[0].dCodInt = "000001";
                dataDE.gDtipDE.gCamCond.iCondOpe = 6;
                dataDE.gDtipDE.gCamCond.dDCondOpe = null;
                dataDE.gDatGralOpe.gDatRec.dRucRec = "80026041";
                dataDE.gDatGralOpe.gOpeCom.cMoneOpe = "PYG";
                dataDE.gDatGralOpe.gOpeCom.dDesTImp = "IVA – Renta";
                dataDE.gTotSub.dBaseGrav10 = 454545.45454546;
                // dataDE.gCamDEAsoc.iTipDocAso = null;
                iTiDE !== 4
                  ? (dataDE.gCamDEAsoc = {
                      iTipDocAso: null,
                      dDesTipDocAso: null,
                      dCdCDERef: null,
                    })
                  : (dataDE.gCamDEAsoc = {
                      iTipDocAso: 3,
                      dDesTipDocAso: "Constancia Electrónica",
                      iTipCons: 1,
                      dDesTipCons: "Constancia de no ser contribuyente",
                      dNumCons: 0,
                      dNumControl: "",
                    });
                break;
              case 3:
                dataDE.gTotSub.dTotGralOpe = 500000.0;
                dataDE.gDtipDE.gCamItem[0].gValorItem.dTotBruOpeItem = 250000.0;
                dataDE.gDtipDE.gCamItem[0].dCodInt = "000001";
                dataDE.gDtipDE.gCamCond.iCondOpe = 2;
                dataDE.gDtipDE.gCamCond.dDCondOpe = "Credito";
                dataDE.gDatGralOpe.gDatRec.dRucRec = "";
                dataDE.gDatGralOpe.gOpeCom.cMoneOpe = "PYG";
                dataDE.gDatGralOpe.gOpeCom.dDesTImp = "IVA – Renta";
                dataDE.gTotSub.dBaseGrav10 = 454545.45454546;
                // dataDE.gCamDEAsoc.iTipDocAso = null;
                iTiDE !== 4
                  ? (dataDE.gCamDEAsoc = {
                      iTipDocAso: null,
                      dDesTipDocAso: null,
                      dCdCDERef: null,
                    })
                  : (dataDE.gCamDEAsoc = {
                      iTipDocAso: 3,
                      dDesTipDocAso: "Constancia Electrónica",
                      iTipCons: 1,
                      dDesTipCons: "Constancia de no ser contribuyente",
                      dNumCons: 0,
                      dNumControl: "",
                    });
                break;
              case 4:
                dataDE.gTotSub.dTotGralOpe = 500000.0;
                dataDE.gDtipDE.gCamItem[0].gValorItem.dTotBruOpeItem = 250000.0;
                dataDE.gDtipDE.gCamItem[0].dCodInt = "000001";
                dataDE.gDtipDE.gCamCond.iCondOpe = 2;
                dataDE.gDtipDE.gCamCond.dDCondOpe = "Credito";
                dataDE.gDatGralOpe.gDatRec.dRucRec = "80026041";
                dataDE.gDatGralOpe.gOpeCom.cMoneOpe = "PYG";
                dataDE.gDatGralOpe.gOpeCom.dDesTImp = "IVA – Renta";
                dataDE.gTotSub.dBaseGrav10 = 9454545.45454546;
                dataDE.gCamDEAsoc.iTipDocAso = null;
                break;
            }
            switch (iTiDE) {
              case 4:
                dataAU.gDatGralOpe.dFeEmiDE = moment()
                  .subtract(10, "minutes")
                  .format("YYYY-MM-DDTHH:MM:59");
                dataAU.gTimb.dNumTim = cnf.ConfTimb.timbrado;
                dataAU.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
                dataAU.gTimb.dEst = cnf.ConfTimb.establecimiento;
                dataAU.gTimb.dPunExp = cnf.ConfTimb.expedicion;
                dataAU.gTimb.dNumDoc = nroFac(
                  `${parseFloat(cnf.ConfTimb.numeroini) + parseFloat(i)}`
                );
                break;
              case 5:
                dataDE.gDtipDE.gCamNCDE.iMotEmi = 2;
                dataDE.gDtipDE.gCamNCDE.dDesMotEmi = "Devolución";
                dataDE.gCamDEAsoc.iTipDocAso = 1;
                dataDE.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                dataDE.gCamDEAsoc.dCdCDERef =
                  lis_FE_Sinc_Aprob && lis_FE_Sinc_Aprob.length > 0
                    ? lis_FE_Sinc_Aprob[i].cdc
                    : lis_Test[i].CDC;
                break;
              case 6:
                dataDE.gDtipDE.gCamNCDE.iMotEmi = 3;
                dataDE.gDtipDE.gCamNCDE.dDesMotEmi = "Descuento";
                dataDE.gCamDEAsoc.iTipDocAso = 1;
                dataDE.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                dataDE.gCamDEAsoc.dCdCDERef =
                  lis_FE_Sinc_Aprob && lis_FE_Sinc_Aprob.length > 0
                    ? lis_FE_Sinc_Aprob[i].cdc
                    : lis_Test[i].CDC;
                break;
            }
            console.log("dataDE_Sinc_Recha", dataDE);
            const resp = await fetch(
              `${MainUrl}rest/enviarDE_Test?ruc=${getItemSession(
                "ruc"
              )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataDE),
              }
            );
            if (resp.status === 200) {
              pasa += i;
              if (i === 4) {
                termina = true;
                setLoading(false);
                setConfigPrueba(cnf.ConfTimb);
                // setConfigPrueba({
                //   ...ConfigPrueba,
                //   ruc: cnf.ConfTimb.ruc,
                //   establecimiento: cnf.ConfTimb.establecimiento,
                //   expedicion: cnf.ConfTimb.expedicion,
                //   timbrado: cnf.ConfTimb.timbrado,
                //   vigenciaini: cnf.ConfTimb.vigenciaini,
                //   numeroini: nroFac(parseFloat(i)),
                // });
              }
            }
          }

          break;
        case "Asincrono-Aprobado":
          for (let x = 0; x < 2; x++) {
            dataAsync = [];
            for (let i = 1; i < 6; i++) {
              let facte = iTiDE === 4 ? { ...dataAUA } : { ...dataDEA };
              facte.gTimb.iTiDE = iTiDE;
              facte.gTimb.dDesTiDE = DesTiDE;
              facte.gDatGralOpe.dFeEmiDE = moment()
                .subtract(10, "minutes")
                .format("YYYY-MM-DDTHH:MM:59");
              facte.gTimb.dNumTim = cnf.ConfTimb.timbrado;
              facte.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
              facte.gTimb.dEst = cnf.ConfTimb.establecimiento;
              facte.gTimb.dPunExp = cnf.ConfTimb.expedicion;
              facte.gDatGralOpe.gEmis = cnf.GEmis;
              switch (iTiDE) {
                case 4:
                  facte.gDatGralOpe.gDatRec.dRucRec = cnf.GEmis.dRucEm;
                  facte.gDatGralOpe.gDatRec.dDVRec = cnf.GEmis.dDVEmi;
                  facte.gDatGralOpe.gDatRec.dNomRec = cnf.GEmis.dNomEmi;
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  break;
                case 5:
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  facte.gDtipDE.gCamNCDE.iMotEmi = 2;
                  facte.gDtipDE.gCamNCDE.dDesMotEmi = "Devolución";
                  facte.gCamDEAsoc.iTipDocAso = 1;
                  facte.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                  // facte.gCamDEAsoc.dCdCDERef = lis_FE_Sinc_Aprob[i].cdc;
                  break;
                case 6:
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  facte.gDtipDE.gCamNCDE.iMotEmi = 3;
                  facte.gDtipDE.gCamNCDE.dDesMotEmi = "Descuento";
                  facte.gCamDEAsoc.iTipDocAso = 1;
                  facte.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                  // facte.gCamDEAsoc.dCdCDERef = lis_FE_Sinc_Aprob[i].cdc;
                  break;
                default:
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  break;
              }
              dataAsync.push({ ...facte });
            }
            if (iTiDE === 4) {
              dataAsync = dataAsync.map((item, index) => ({
                ...dataAUA,
                gTimb: {
                  ...dataAUA.gTimb,
                  dNumDoc: nroFac(
                    `${
                      parseFloat(cnf.ConfTimb.numeroini) +
                      parseFloat(x === 0 ? index : index + 5)
                    }`
                  ),
                },
                gCamDEAsoc: {
                  ...dataAUA.gCamDEAsoc,
                  dCdCDERef:
                    iTiDE === 4 || iTiDE === 5 || iTiDE === 6
                      ? // lis_FE_ASinc_Aprob.filter(
                        //     (i) => i.estado === "Aprobado"
                        //   )[index].cdc
                        lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                        ? lis_FE_ASinc_Aprob.filter(
                            (i) => i.estado === "Aprobado"
                          )[index].cdc
                        : lis_Test[index].CDC
                      : "",
                },
              }));
            } else {
              dataAsync = dataAsync.map((item, index) => ({
                ...dataDEA,
                gTimb: {
                  ...dataDEA.gTimb,
                  dNumDoc: nroFac(
                    `${
                      parseFloat(cnf.ConfTimb.numeroini) +
                      parseFloat(x === 0 ? index : index + 5)
                    }`
                  ),
                },
                gCamDEAsoc: {
                  ...dataDEA.gCamDEAsoc,
                  dCdCDERef:
                    iTiDE === 4 || iTiDE === 5 || iTiDE === 6
                      ? // lis_FE_ASinc_Aprob.filter(
                        //     (i) => i.estado === "Aprobado"
                        //   )[index].cdc
                        lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                        ? lis_FE_ASinc_Aprob.filter(
                            (i) => i.estado === "Aprobado"
                          )[index].cdc
                        : lis_Test[index].CDC
                      : "",
                },
              }));
            }
            console.log("dataAsync_Aprob", dataAsync);
            const response = await fetch(
              `${MainUrl}rest/enviarLoteDE_Test?ruc=${getItemSession(
                "ruc"
              )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataAsync),
              }
            );
            let json = { estado: "false" };
            if (response.status === 200) {
              json = await response.json();
              const resp = await fetch(
                `${MainUrl}rest/consultarLote_Test?ruc=${getItemSession(
                  "ruc"
                )}&codigo=${json.lote}`
              );
              if (resp.status === 200) {
                setLoading(false);
                setConfigPrueba(cnf.ConfTimb);
              }
            }
          }
          break;
        case "Asincrono-Rechazado":
          for (let x = 0; x < 2; x++) {
            dataAsync = [];
            for (let i = 1; i < 6; i++) {
              let facte = iTiDE === 4 ? { ...dataAUA } : { ...dataDEA };
              facte.gTimb.iTiDE = iTiDE;
              facte.gTimb.dDesTiDE = DesTiDE;
              facte.gDatGralOpe.dFeEmiDE = moment()
                .subtract(10, "minutes")
                .format("YYYY-MM-DDTHH:MM:59");
              facte.gTimb.dNumTim = cnf.ConfTimb.timbrado;
              facte.gTimb.dFeIniT = cnf.ConfTimb.vigenciaini;
              facte.gTimb.dEst = cnf.ConfTimb.establecimiento;
              facte.gTimb.dPunExp = cnf.ConfTimb.expedicion;
              switch (iTiDE) {
                case 4:
                  break;
                case 5:
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  facte.gDtipDE.gCamNCDE.iMotEmi = 2;
                  facte.gDtipDE.gCamNCDE.dDesMotEmi = "Devolución";
                  facte.gCamDEAsoc.iTipDocAso = 1;
                  facte.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                  break;
                case 6:
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  facte.gDtipDE.gCamNCDE.iMotEmi = 3;
                  facte.gDtipDE.gCamNCDE.dDesMotEmi = "Descuento";
                  facte.gCamDEAsoc.iTipDocAso = 1;
                  facte.gCamDEAsoc.dDesTipDocAso = "Electrónico";
                  break;
                default:
                  facte.gDatGralOpe.gEmis = cnf.GEmis;
                  break;
              }
              dataAsync.push({ ...facte });
            }
            if (iTiDE === 4) {
              dataAsync = dataAsync.map((item, index) => ({
                ...dataAUA,
                gTimb: {
                  ...dataAUA.gTimb,
                  dNumDoc: nroFac(
                    `${
                      parseFloat(cnf.ConfTimb.numeroini) +
                      parseFloat(x === 0 ? index : index + 5)
                    }`
                  ),
                },
                gCamDEAsoc: {
                  ...dataAUA.gCamDEAsoc,
                  // dCdCDERef: lis_FE_ASinc_Aprob.filter(
                  //   (i) => i.estado === "Aprobado"
                  // )[index].cdc,
                  dCdCDERef:
                    lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                      ? lis_FE_ASinc_Aprob.filter(
                          (i) => i.estado === "Aprobado"
                        )[index].cdc
                      : lis_Test[index].CDC,
                },
                gDatRec: {
                  ...dataAUA.gDatGralOpe.gDatRec,
                  dRucRec: index % 3 === 0 ? "" : "80026041",
                },
                gTotSub: {
                  ...dataAUA.gTotSub,
                  dTotGralOpe: index % 2 === 0 ? 9000000.0 : 500000.0,
                },
                gOpeCom: {
                  ...dataAUA.gOpeCom,
                  dDesTImp:
                    index % 2 !== 0 && index % 3 !== 0 && index % 5 !== 0
                      ? "UU"
                      : "IVA – Renta",
                },
                gCamFE: {
                  ...dataAUA.gDtipDE.gCamFE,
                  iIndPres: index % 5 === 0 ? null : 1,
                },
              }));
            } else {
              dataAsync = dataAsync.map((item, index) => ({
                ...dataDEA,
                gTimb: {
                  ...dataDEA.gTimb,
                  dNumDoc: nroFac(
                    `${
                      parseFloat(cnf.ConfTimb.numeroini) +
                      parseFloat(x === 0 ? index : index + 5)
                    }`
                  ),
                },
                gCamDEAsoc: {
                  ...dataDEA.gCamDEAsoc,
                  dCdCDERef:
                    iTiDE === 4 || iTiDE === 5 || iTiDE === 6
                      ? // lis_FE_ASinc_Aprob.filter(
                        //     (i) => i.estado === "Aprobado"
                        //   )[index].cdc
                        lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                        ? lis_FE_ASinc_Aprob.filter(
                            (i) => i.estado === "Aprobado"
                          )[index].cdc
                        : lis_Test[index].CDC
                      : "",
                },
                gDatRec: {
                  ...dataDEA.gDatGralOpe.gDatRec,
                  dRucRec: index % 3 === 0 ? "" : "80026041",
                },
                gTotSub: {
                  ...dataDEA.gTotSub,
                  dTotGralOpe: index % 2 === 0 ? 9000000.0 : 500000.0,
                },
                gOpeCom: {
                  ...dataDEA.gOpeCom,
                  dDesTImp:
                    index % 2 !== 0 && index % 3 !== 0 && index % 5 !== 0
                      ? "UU"
                      : "IVA – Renta",
                },
                gCamItem: {
                  ...dataDEA.gDtipDE.gCamItem,
                  dCodInt: index % 5 === 0 ? null : "000001",
                },
              }));
            }
            console.log("dataAsync_Recha", dataAsync);
            const respons = await fetch(
              `${MainUrl}rest/enviarLoteDE_Test?ruc=${getItemSession(
                "ruc"
              )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataAsync),
              }
            );
            let jSon = { estado: "false" };
            if (respons.status === 200) {
              jSon = await respons.json();
              const resp = await fetch(
                `${MainUrl}rest/consultarLote_Test?ruc=${getItemSession(
                  "ruc"
                )}&codigo=${jSon.lote}`
              );
              if (resp.status === 200) {
                setLoading(false);
                setConfigPrueba(cnf.ConfTimb);
              }
            }
          }

          break;
        case "Cancelacion":
          lis_FE_Cancelacion.map((it, idx) => {
            lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
              ? lis_FE_ASinc_Aprob
                  .filter((i) => i.estado === "Aprobado")
                  .splice(
                    lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                    1
                  )
              : lis_Test
                  .filter((i) => i.tipoDe === "Factura electrónica")
                  .splice(
                    lis_Test.find((t) => t.CDC === it.cdc),
                    1
                  );
          });
          lis_NC_Cancelacion.map((it, idx) => {
            lis_NC_ASinc_Aprob && lis_NC_ASinc_Aprob.length > 0
              ? lis_NC_ASinc_Aprob
                  .filter((i) => i.estado === "Aprobado")
                  .splice(
                    lis_NC_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                    1
                  )
              : lis_Test
                  .filter((i) => i.tipoDe === "Nota de crédito electrónica")
                  .splice(
                    lis_Test.find((t) => t.CDC === it.cdc),
                    1
                  );
          });
          lis_ND_Cancelacion.map((it, idx) => {
            lis_ND_ASinc_Aprob && lis_ND_ASinc_Aprob.length > 0
              ? lis_ND_ASinc_Aprob
                  .filter((i) => i.estado === "Aprobado")
                  .splice(
                    lis_ND_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                    1
                  )
              : lis_Test
                  .filter((i) => i.tipoDe === "Nota de débito electrónica")
                  .splice(
                    lis_Test.find((t) => t.CDC === it.cdc),
                    1
                  );
          });
          lis_AU_Cancelacion.map((it, idx) => {
            lis_AU_Cancelacion && lis_AU_Cancelacion.length > 0
              ? lis_AU_Cancelacion
                  .filter((i) => i.estado === "Aprobado")
                  .splice(
                    lis_AU_Cancelacion.find((t) => t.cdc === it.cdc),
                    1
                  )
              : lis_Test
                  .filter((i) => i.tipoDe === "Autofactura electrónica")
                  .splice(
                    lis_Test.find((t) => t.CDC === it.cdc),
                    1
                  );
          });
          setTimeout(() => {
            switch (iTiDE) {
              case 1:
                getMultipleRandom(
                  // lis_FE_ASinc_Aprob.filter((i) => i.estado === "Aprobado")
                  lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                    ? lis_FE_ASinc_Aprob.filter((i) => i.estado === "Aprobado")
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Factura electrónica"
                      ),
                  5
                ).map((item, index) => {
                  cancela(item, index, DesTiDE, tipoWs);
                });
                break;
              case 4:
                getMultipleRandom(
                  // lis_AU_ASinc_Aprob.filter((i) => i.estado === "Aprobado"),
                  lis_AU_ASinc_Aprob && lis_AU_ASinc_Aprob.length > 0
                    ? lis_AU_ASinc_Aprob.filter((i) => i.estado === "Aprobado")
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Autofactura electrónica"
                      ),
                  5
                ).map((item, index) => {
                  cancela(item, index, DesTiDE, tipoWs);
                });
                break;
              case 5:
                getMultipleRandom(
                  // lis_NC_ASinc_Aprob.filter((i) => i.estado === "Aprobado"),
                  lis_NC_ASinc_Aprob && lis_NC_ASinc_Aprob.length > 0
                    ? lis_NC_ASinc_Aprob.filter((i) => i.estado === "Aprobado")
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Nota de crédito electrónica"
                      ),
                  5
                ).map((item, index) => {
                  cancela(item, index, DesTiDE, tipoWs);
                });
                break;
              case 6:
                getMultipleRandom(
                  // lis_ND_ASinc_Aprob.filter((i) => i.estado === "Aprobado"),
                  lis_ND_ASinc_Aprob && lis_ND_ASinc_Aprob.length > 0
                    ? lis_ND_ASinc_Aprob.filter((i) => i.estado === "Aprobado")
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Nota de débito electrónica"
                      ),
                  5
                ).map((item, index) => {
                  cancela(item, index, DesTiDE, tipoWs);
                });
                break;
              case 7:
                break;
            }
          }, 300);
          break;
        case "Inutilizacion":
          switch (iTiDE) {
            case 1:
              inuparam.dNumTim = parseFloat(cnf.ConfTimb.timbrado);
              inuparam.dEst = "001";
              inuparam.dPunExp = "001";
              inuparam.dNumIn = "9999990";
              inuparam.dNumFin = "9999994";
              inuparam.iTiDE = iTiDE;
              inuparam.mOtEve = "Inutilización de documentos fase de prueba";
              inutiliza(DesTiDE, tipoWs);
              setTimeout(() => {
                inuparam.dNumTim = parseFloat(cnf.ConfTimb.timbrado);
                inuparam.dEst = "001";
                inuparam.dPunExp = "001";
                inuparam.dNumIn = "9999995";
                inuparam.dNumFin = "9999999";
                inuparam.iTiDE = iTiDE;
                inuparam.mOtEve = "Inutilización de documentos fase de prueba";
                inutiliza(DesTiDE, tipoWs);
              }, 2000);
              break;
            case 4:
              inuparam.dNumTim = parseFloat(cnf.ConfTimb.timbrado);
              inuparam.dEst = "001";
              inuparam.dPunExp = "001";
              inuparam.dNumIn = "9999995";
              inuparam.dNumFin = "9999999";
              inuparam.iTiDE = iTiDE;
              inuparam.mOtEve = "Inutilización de documentos fase de prueba";
              inutiliza(DesTiDE, tipoWs);
              break;
            case 5:
              inuparam.dNumTim = parseFloat(cnf.ConfTimb.timbrado);
              inuparam.dEst = "001";
              inuparam.dPunExp = "001";
              inuparam.dNumIn = "9999995";
              inuparam.dNumFin = "9999999";
              inuparam.iTiDE = iTiDE;
              inuparam.mOtEve = "Inutilización de documentos fase de prueba";
              inutiliza(DesTiDE, tipoWs);
              break;
            case 6:
              inuparam.dNumTim = parseFloat(cnf.ConfTimb.timbrado);
              inuparam.dEst = "001";
              inuparam.dPunExp = "001";
              inuparam.dNumIn = "9999995";
              inuparam.dNumFin = "9999999";
              inuparam.iTiDE = iTiDE;
              inuparam.mOtEve = "Inutilización de documentos fase de prueba";
              inutiliza(DesTiDE, tipoWs);
              break;
            case 7:
              break;
          }
          break;
        case "Receptor":
          // console.log("tipo", iTiDE);
          switch (iTiDE) {
            case 1: //"conformidad":
              lis_FE_Cancelacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Disconformidad.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Desconocimiento.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      ); //   );
              });
              lis_Notificacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });

              setTimeout(() => {
                getMultipleRandom(
                  lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                    ? lis_FE_ASinc_Aprob.filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Factura electrónica"
                      ),
                  8
                ).map((item, index) => {
                  confirma(index, item.cdc, "conformidad", tipoWs);
                });
              }, 500);
              break;
            case 5: //"disconformidad":
              lis_FE_Cancelacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Conformidad.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Desconocimiento.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Notificacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              setTimeout(() => {
                getMultipleRandom(
                  lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                    ? lis_FE_ASinc_Aprob.filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Factura electrónica"
                      ),
                  8
                ).map((item, index) => {
                  noconfirma(index, item.cdc, "disconformidad", tipoWs);
                });
              }, 500);
              break;
            case 6: //"desconocimiento":
              lis_FE_Cancelacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Conformidad.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Disconformidad.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Notificacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              setTimeout(() => {
                getMultipleRandom(
                  lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                    ? lis_FE_ASinc_Aprob.filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Factura electrónica"
                      ),
                  8
                ).map((item, index) => {
                  desconoce(index, item.cdc, "desconocimiento", tipoWs);
                });
              }, 500);
              break;
            case 4: //"notificacion":
              lis_FE_Cancelacion.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Conformidad.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Disconformidad.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              lis_Desconocimiento.map((it, idx) => {
                lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                  ? lis_FE_ASinc_Aprob
                      .filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                      .splice(
                        lis_FE_ASinc_Aprob.find((t) => t.cdc === it.cdc),
                        1
                      )
                  : lis_Test
                      .filter((i) => i.tipoDe === "Factura electrónica")
                      .splice(
                        lis_Test.find((t) => t.CDC === it.cdc),
                        1
                      );
              });
              setTimeout(() => {
                getMultipleRandom(
                  lis_FE_ASinc_Aprob && lis_FE_ASinc_Aprob.length > 0
                    ? lis_FE_ASinc_Aprob.filter(
                        (i) =>
                          i.estado === "Aprobado" &&
                          i.tipoDe === "Factura electrónica"
                      )
                    : lis_Test.filter(
                        (i) => i.tipoDe === "Factura electrónica"
                      ),
                  8
                ).map((item, index) => {
                  notificado(index, item.cdc, "notificacion", tipoWs);
                });
              }, 500);
              break;
          }
          break;
        case "Consulta":
          switch (iTiDE) {
            case 1:
              lis_FE_Sinc_Aprob && lis_FE_Sinc_Aprob.length > 0
                ? lis_FE_Sinc_Aprob
                    .filter((i) => i.estado === "Aprobado")
                    .splice(0, 3)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    })
                : lis_Test
                    .filter((i) => i.tipoDe === "Factura electrónica")
                    .splice(0, 3)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    });
              break;
            case 4:
              lis_AU_Sinc_Aprob && lis_AU_Sinc_Aprob.length > 0
                ? lis_AU_Sinc_Aprob
                    .filter((i) => i.estado === "Aprobado")
                    .splice(0, 2)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    })
                : lis_Test
                    .filter((i) => i.tipoDe === "Factura electrónica")
                    .splice(0, 2)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    });
              break;
            case 5:
              lis_NC_Sinc_Aprob && lis_NC_Sinc_Aprob.length > 0
                ? lis_NC_Sinc_Aprob
                    .filter((i) => i.estado === "Aprobado")
                    .splice(0, 2)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    })
                : lis_Test
                    .filter((i) => i.tipoDe === "Factura electrónica")
                    .splice(0, 2)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    });
              break;
            case 6:
              lis_ND_Sinc_Aprob && lis_ND_Sinc_Aprob.length > 0
                ? lis_ND_Sinc_Aprob
                    .filter((i) => i.estado === "Aprobado")
                    .splice(0, 2)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    })
                : lis_Test
                    .filter((i) => i.tipoDe === "Factura electrónica")
                    .splice(0, 2)
                    .map((item, index) => {
                      consultar(index, item.cdc, DesTiDE, tipoWs);
                    });
              break;
            case 7:
              break;
          }
          break;
      }
    } else {
      respuesta = false;
      toast.warning("No se ha cargado la información del Emisor", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setConfigPrueba(cnf.ConfTimb);
      setLoading(false);
    }
    if (termina) {
      respuesta = true;
      toast("Documentos Enviados", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setConfigPrueba(cnf.ConfTimb);
      setLoading(false);
    }
  };

  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }

  const cancela = async (ite, i, DesTiDE, tipoWs) => {
    let cnf = await getConfigPrueba();
    let resp = await fetch(
      `${MainUrl}rest/cancelar_Test?ruc=${getItemSession("ruc")}&cdc=${
        ite.cdc
      }&motivo=Cancelacion de CDC fase de prueba&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`
    );
    if (resp.status === 200) {
      if (i === 4) {
        setLoading(false);
        setConfigPrueba(cnf.ConfTimb);
      }
    }
  };

  const inutiliza = async (DesTiDE, tipoWs) => {
    let cnf = await getConfigPrueba();
    const resp = await fetch(
      `${MainUrl}rest/inutilizar_Test?ruc=${getItemSession(
        "ruc"
      )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inuparam),
      }
    );
    if (resp.status === 200) {
      setLoading(false);
      setConfigPrueba(cnf.ConfTimb);
    }
  };

  const confirma = async (i, cdc, DesTiDE, tipoWs) => {
    let cnf = await getConfigPrueba();
    const resp = await fetch(
      `${MainUrl}rest/receptor/conformidad?ruc=${getItemSession(
        "ruc"
      )}&cdc=${cdc}&tipo=1&fecharecep=${moment().format(
        "YYYY-MM-DD"
      )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`
    );
    if (resp.status === 200) {
      if (i === 4) {
        setLoading(false);
        setConfigPrueba(cnf.ConfTimb);
      }
    }
  };

  const noconfirma = async (i, cdc, DesTiDE, tipoWs) => {
    let cnf = await getConfigPrueba();
    const resp = await fetch(
      `${MainUrl}rest/receptor/disconformidad?ruc=${getItemSession(
        "ruc"
      )}&cdc=${cdc}&motivo=Disconformidad con CDC fase de prueba&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`
    );
    if (resp.status === 200) {
      if (i === 4) {
        setLoading(false);
        setConfigPrueba(cnf.ConfTimb);
      }
    }
  };

  const desconoce = async (i, cdc, DesTiDE, tipoWs) => {
    let facte = { ...dataDEA };
    let cnf = await getConfigPrueba();
    disconfparam.Id = cdc;
    disconfparam.dFecEmi = moment()
      .subtract(10, "minutes")
      .format("YYYY-MM-DDTHH:MM:59");
    disconfparam.dFecRecep = moment()
      .subtract(10, "minutes")
      .format("YYYY-MM-DDTHH:MM:59");
    disconfparam.iTipRec = facte.gDatGralOpe.gDatRec.iNatRec;
    disconfparam.dNomRec = facte.gDatGralOpe.gDatRec.dNomRec;
    disconfparam.dRucRec = facte.gDatGralOpe.gDatRec.dRucRec;
    disconfparam.dDVRec = facte.gDatGralOpe.gDatRec.dDVRec;
    disconfparam.mOtEve = "Desconocimiento de documentos fase de prueba";
    const resp = await fetch(
      `${MainUrl}rest/receptor/desconocimiento?ruc=${getItemSession(
        "ruc"
      )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(disconfparam),
      }
    );
    if (resp.status === 200) {
      if (i === 4) {
        setLoading(false);
        setConfigPrueba(cnf.ConfTimb);
      }
    }
  };

  const notificado = async (i, cdc, DesTiDE, tipoWs) => {
    let facte = { ...dataDEA };
    let cnf = await getConfigPrueba();
    notificadoparam.Id = cdc;
    notificadoparam.dFecEmi = moment()
      .subtract(10, "minutes")
      .format("YYYY-MM-DDTHH:MM:59");
    notificadoparam.dFecRecep = moment()
      .subtract(10, "minutes")
      .format("YYYY-MM-DDTHH:MM:59");
    notificadoparam.iTipRec = facte.gDatGralOpe.gDatRec.iNatRec;
    notificadoparam.dNomRec = facte.gDatGralOpe.gDatRec.dNomRec;
    notificadoparam.dRucRec = facte.gDatGralOpe.gDatRec.dRucRec;
    notificadoparam.dDVRec = facte.gDatGralOpe.gDatRec.dDVRec;
    notificadoparam.dTotalGs = 0;
    const resp = await fetch(
      `${MainUrl}rest/receptor/recepcionar?ruc=${getItemSession(
        "ruc"
      )}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(notificadoparam),
      }
    );
    if (resp.status === 200) {
      if (i === 4) {
        setLoading(false);
        setConfigPrueba(cnf.ConfTimb);
      }
    }
  };

  const consultar = async (i, cdc, DesTiDE, tipoWs) => {
    let cnf = await getConfigPrueba();
    const resp = await fetch(
      `${MainUrl}rest/consultarDE_Test?ruc=${getItemSession(
        "ruc"
      )}&codigo=${cdc}&tipoDe=${DesTiDE}&tipoWs=${tipoWs}`
    );
    if (resp.status === 200) {
      setLoading(false);
      setConfigPrueba(cnf.ConfTimb);
    }
  };

  /*********************************FIN ACCIONES*******************************/

  const [sincronoOk, setSincronoOk] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [sincronoError, setSincronoError] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [asincronoOk, setAsincronoOk] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [asincronoError, setAsincronoError] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [cancelacion, setCancelacion] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [inutilizacion, setInutilizacion] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [receptor, setReceptor] = useState({
    conformidad: false,
    disconformidad: false,
    desconocimiento: false,
    notificacion: false,
    ajuste: false,
  });
  const [consulta, setConsulta] = useState({
    fe: false,
    nc: false,
    nd: false,
    au: false,
    nr: false,
  });
  const [ConfigPrueba, setConfigPrueba] = useState({
    ruc: getItemSession("ruc"),
    timbrado: "",
    establecimiento: "",
    expedicion: "",
    vigenciaini: "",
    numeroini: "",
  });

  const handleChange = async (event) => {
    setConfigPrueba({
      ...ConfigPrueba,
      [event.target.name]: event.target.value,
    });
  };

  const handleBlur = (event) => {
    setConfigPrueba({
      ...ConfigPrueba,
      [event.target.name]: nroFac(event.target.value),
    });
  };

  const grabaconf = async () => {
    let res = await grabaConfigPrueba(ConfigPrueba);
  };

  const [open, setOpen] = React.useState(false);
  const [dataD, setDataD] = useState();
  const handleOpenDoc = (data) => {
    setDataD(data.slice(0, 10));
    setOpen(true);
  };
  const handleOpenDocLote = (data) => {
    setDataD(data.slice(0, 10));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConsultaLote = async () => {
    // const nroLote = dataD.find((item) => item.lote !== "").lote;
    let nroLote = "";
    dataD.map((item, index) => {
      if (item.lote !== nroLote) {
        nroLote = item.lote;
        consLOT(nroLote);
      }
    });
  };

  async function consLOT(nroLote) {
    if (nroLote !== undefined) {
      const resp = await fetch(
        `${MainUrl}rest/consultarLote_Test?ruc=${getItemSession(
          "ruc"
        )}&codigo=${nroLote}`
      );
      if (resp.status === 200) {
        let config = await getConfigPrueba();
        setConfigPrueba(config.ConfTimb);
        handleClose();
      }
    }
  }

  const handleExcel = () => {
    window.location.href = `${MainUrl}rest/Excel?ruc=${getItemSession(
      "ruc"
    )}&nombre=${getItemSession("nombre")}`;
  };

  const handlePDF = () => {
    const doc = "./Declaracion_Cumplimiento.docx";
    const anchor = document.createElement("a");
    anchor.href = doc;
    anchor.download = doc;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(async () => {
    let docs = await getDocsPrueba();
    console.log("docs", docs);
    setlis_Test(docs.lis_Test);
    setlis_FE_Sinc_Aprob(docs.lis_FE_Sinc_Aprob);
    setlis_NC_Sinc_Aprob(docs.lis_NC_Sinc_Aprob);
    setlis_ND_Sinc_Aprob(docs.lis_ND_Sinc_Aprob);
    setlis_AU_Sinc_Aprob(docs.lis_AU_Sinc_Aprob);
    setlis_NR_Sinc_Aprob(docs.lis_NR_Sinc_Aprob);
    setSincronoOk({
      ...sincronoOk,
      fe: docs.FE_Sinc_Aprob,
      nc: docs.NC_Sinc_Aprob,
      nd: docs.ND_Sinc_Aprob,
      au: docs.AU_Sinc_Aprob,
      nr: docs.NR_Sinc_Aprob,
    });
    setlis_FE_Sinc_Recha(docs.lis_FE_Sinc_Recha);
    setlis_NC_Sinc_Recha(docs.lis_NC_Sinc_Recha);
    setlis_ND_Sinc_Recha(docs.lis_ND_Sinc_Recha);
    setlis_AU_Sinc_Recha(docs.lis_AU_Sinc_Recha);
    setlis_NR_Sinc_Recha(docs.lis_NR_Sinc_Recha);
    setSincronoError({
      ...sincronoError,
      fe: docs.FE_Sinc_Recha,
      nc: docs.NC_Sinc_Recha,
      nd: docs.ND_Sinc_Recha,
      au: docs.AU_Sinc_Recha,
      nr: docs.NR_Sinc_Recha,
    });
    setlis_FE_ASinc_Aprob(docs.lis_FE_Asinc_Aprob);
    setlis_NC_ASinc_Aprob(docs.lis_NC_Asinc_Aprob);
    setlis_ND_ASinc_Aprob(docs.lis_ND_Asinc_Aprob);
    setlis_AU_ASinc_Aprob(docs.lis_AU_Asinc_Aprob);
    setlis_NR_ASinc_Aprob(docs.lis_NR_Asinc_Aprob);
    setAsincronoOk({
      ...asincronoOk,
      fe: docs.FE_Asinc_Aprob,
      nc: docs.NC_Asinc_Aprob,
      nd: docs.ND_Asinc_Aprob,
      au: docs.AU_Asinc_Aprob,
      nr: docs.NR_Asinc_Aprob,
    });
    setlis_FE_ASinc_Recha(docs.lis_FE_Asinc_Recha);
    setlis_NC_ASinc_Recha(docs.lis_NC_Asinc_Recha);
    setlis_ND_ASinc_Recha(docs.lis_ND_Asinc_Recha);
    setlis_AU_ASinc_Recha(docs.lis_AU_Asinc_Recha);
    setlis_NR_ASinc_Recha(docs.lis_NR_Asinc_Recha);
    setAsincronoError({
      ...asincronoError,
      fe: docs.FE_Asinc_Recha,
      nc: docs.NC_Asinc_Recha,
      nd: docs.ND_Asinc_Recha,
      au: docs.AU_Asinc_Recha,
      nr: docs.NR_Asinc_Recha,
    });
    setlis_FE_Cancelacion(docs.lis_FE_Cancelacion);
    setlis_NC_Cancelacion(docs.lis_NC_Cancelacion);
    setlis_ND_Cancelacion(docs.lis_ND_Cancelacion);
    setlis_AU_Cancelacion(docs.lis_AU_Cancelacion);
    setlis_NR_Cancelacion(docs.lis_NR_Cancelacion);
    setCancelacion({
      ...cancelacion,
      fe: docs.FE_Cancelacion,
      nc: docs.NC_Cancelacion,
      nd: docs.ND_Cancelacion,
      au: docs.AU_Cancelacion,
      nr: docs.NR_Cancelacion,
    });
    setlis_FE_Inutilizacion(docs.lis_FE_Inutilizacion);
    setlis_NC_Inutilizacion(docs.lis_NC_Inutilizacion);
    setlis_ND_Inutilizacion(docs.lis_ND_Inutilizacion);
    setlis_AU_Inutilizacion(docs.lis_AU_Inutilizacion);
    setlis_NR_Inutilizacion(docs.lis_NR_Inutilizacion);
    setInutilizacion({
      ...inutilizacion,
      fe: docs.FE_Inutilizacion,
      nc: docs.NC_Inutilizacion,
      nd: docs.ND_Inutilizacion,
      au: docs.AU_Inutilizacion,
      nr: docs.NR_Inutilizacion,
    });
    setlis_Conformidad(docs.lis_Conformidad);
    setlis_Disconformidad(docs.lis_Disconformidad);
    setlis_Desconocimiento(docs.lis_Desconocimiento);
    setlis_Notificacion(docs.lis_Notificacion);
    setlis_Ajuste(docs.lis_Ajuste);
    setReceptor({
      ...receptor,
      conformidad: docs.Conformidad,
      disconformidad: docs.Disconformidad,
      desconocimiento: docs.Desconocimiento,
      notificacion: docs.Notificacion,
      ajuste: docs.Ajuste,
    });
    setlis_FE_Consulta(docs.lis_FE_Consulta);
    setlis_NC_Consulta(docs.lis_NC_Consulta);
    setlis_ND_Consulta(docs.lis_ND_Consulta);
    setlis_AU_Consulta(docs.lis_AU_Consulta);
    setlis_NR_Consulta(docs.lis_NR_Consulta);
    setConsulta({
      ...consulta,
      fe: docs.FE_Consulta,
      nc: docs.NC_Consulta,
      nd: docs.ND_Consulta,
      au: docs.AU_Consulta,
      nr: docs.NR_Consulta,
    });
  }, [ConfigPrueba]);

  useEffect(async () => {
    let config = await getConfigPrueba();
    if (config === "") {
      toast.error("Debe cargar la configuracion de Pruebas", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setConfigPrueba(config.ConfTimb);
    }
  }, []);

  return (
    <Fragment>
      <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <Paper
              sx={{
                width: "100%",
                maxWidth: "100%",
              }}
              className="header"
            >
              <Grid container>
                <Grid size={12} style={stylePad}>
                  <h3>Configuración de datos de prueba</h3>
                  <div>
                    <TextField
                      id="timbrado"
                      name="timbrado"
                      label="Timbrado"
                      variant="outlined"
                      size="small"
                      style={{ marginBottom: "10px" }}
                      inputProps={{ maxLength: 8 }}
                      onChange={handleChange}
                      value={ConfigPrueba.timbrado}
                    />
                    <TextField
                      id="establecimiento"
                      name="establecimiento"
                      label="Establecimiento"
                      variant="outlined"
                      size="small"
                      style={styleM}
                      inputProps={{ maxLength: 3 }}
                      onChange={handleChange}
                      value={ConfigPrueba.establecimiento}
                    />
                    <TextField
                      id="expedicion"
                      name="expedicion"
                      label="Punto de expedición"
                      variant="outlined"
                      size="small"
                      style={styleM}
                      inputProps={{ maxLength: 3 }}
                      onChange={handleChange}
                      value={ConfigPrueba.expedicion}
                    />
                    <TextField
                      id="vigenciaini"
                      name="vigenciaini"
                      label="Fecha inicio de vigencia"
                      variant="outlined"
                      size="small"
                      type="date"
                      style={styleM}
                      onChange={handleChange}
                      value={ConfigPrueba.vigenciaini}
                    />
                    <TextField
                      id="numeroini"
                      name="numeroini"
                      label="Numero Inicial"
                      variant="outlined"
                      size="small"
                      style={styleM}
                      inputProps={{ maxLength: 7 }}
                      value={ConfigPrueba.numeroini}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Button
                      style={styleM}
                      variant="contained"
                      color="success"
                      onClick={grabaconf}
                    >
                      Guardar Nro
                    </Button>
                  </div>
                </Grid>
                <Grid size={12} style={stylePad}>
                  <h3>
                    Transmisión de Documentos Electrónicos (DE), ejecución de
                    las validaciones, y obtención del resultado
                  </h3>
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "calc(75vh)",
                    overflowY: "scroll",
                  }}
                >
                  {loading ? (
                    <>
                      <Grid size={12} style={{ padding: "15%" }}>
                        <CircularProgress
                          style={{ width: "120px", height: "120px" }}
                          // disableShrink
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      {/* SINCRONO */}
                      <Grid size={6} style={stylePad}>
                        <h3>WEB SERVICE SINCRONICO - APROBACIÓN</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDoc}
                          lis_FE={lis_FE_Sinc_Aprob}
                          lis_NC={lis_NC_Sinc_Aprob}
                          lis_ND={lis_ND_Sinc_Aprob}
                          lis_AU={lis_AU_Sinc_Aprob}
                          lis_NR={[]}
                          tipoWs="Sincrono-Aprobado"
                          enviado={sincronoOk}
                        />
                      </Grid>
                      <Grid size={6} style={stylePad}>
                        <h3>WEB SERVICE SINCRONICO - RECHAZO</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDoc}
                          lis_FE={lis_FE_Sinc_Recha}
                          lis_NC={lis_NC_Sinc_Recha}
                          lis_ND={lis_ND_Sinc_Recha}
                          lis_AU={lis_AU_Sinc_Recha}
                          lis_NR={[]}
                          tipoWs="Sincrono-Rechazado"
                          enviado={sincronoError}
                        />
                      </Grid>
                      {/* ASINCRONO */}
                      <Grid size={6} style={stylePad}>
                        <h3>WEB SERVICE ASINCRONICO - APROBACIÓN</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDocLote}
                          lis_FE={lis_FE_ASinc_Aprob}
                          lis_NC={lis_NC_ASinc_Aprob}
                          lis_ND={lis_ND_ASinc_Aprob}
                          lis_AU={lis_AU_ASinc_Aprob}
                          lis_NR={[]}
                          tipoWs="Asincrono-Aprobado"
                          enviado={asincronoOk}
                        />
                      </Grid>
                      <Grid size={6} style={stylePad}>
                        <h3>WEB SERVICE ASINCRONICO - RECHAZO</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDocLote}
                          lis_FE={lis_FE_ASinc_Recha}
                          lis_NC={lis_NC_ASinc_Recha}
                          lis_ND={lis_ND_ASinc_Recha}
                          lis_AU={lis_AU_ASinc_Recha}
                          lis_NR={[]}
                          tipoWs="Asincrono-Rechazado"
                          enviado={asincronoError}
                        />
                      </Grid>
                      {/* EVENTOS EMISOR */}
                      {/* CANCELACION */}
                      <Grid size={6} style={stylePad}>
                        <h3>EVENTOS EMISOR - CANCELACIÓN</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDoc}
                          lis_FE={lis_FE_Cancelacion}
                          lis_NC={lis_NC_Cancelacion}
                          lis_ND={lis_ND_Cancelacion}
                          lis_AU={lis_AU_Cancelacion}
                          lis_NR={[]}
                          tipoWs="Cancelacion"
                          enviado={cancelacion}
                        />
                      </Grid>
                      {/* INUTILIZACIÓN */}
                      <Grid size={6} style={stylePad}>
                        <h3>EVENTOS EMISOR - INUTILIZACIÓN</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDoc}
                          lis_FE={lis_FE_Inutilizacion}
                          lis_NC={lis_NC_Inutilizacion}
                          lis_ND={lis_ND_Inutilizacion}
                          lis_AU={lis_AU_Inutilizacion}
                          lis_NR={[]}
                          tipoWs="Inutilizacion"
                          enviado={inutilizacion}
                        />
                      </Grid>
                      {/* EVENTOS RECEPTOR */}
                      <Grid size={6} style={stylePad}>
                        <h3>EVENTOS RECEPTOR</h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDoc}
                          lis_FE={lis_Conformidad}
                          lis_NC={lis_Disconformidad}
                          lis_ND={lis_Desconocimiento}
                          lis_AU={lis_Notificacion}
                          lis_NR={[]}
                          tipoWs="Receptor"
                          enviado={receptor}
                        />
                      </Grid>
                      {/* CONSULTAS */}
                      <Grid size={6} style={stylePad}>
                        <h3>WEB SERVICE CONSULTA </h3>
                        <WebService
                          handleSendWS={handleSendWS}
                          handleOpenDoc={handleOpenDoc}
                          lis_FE={lis_FE_Consulta}
                          lis_NC={lis_NC_Consulta}
                          lis_ND={lis_ND_Consulta}
                          lis_AU={lis_AU_Consulta}
                          lis_NR={[]}
                          tipoWs="Consulta"
                          enviado={consulta}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid size={12} style={stylePad2}>
                  <Button
                    style={styleM}
                    variant="contained"
                    color="secondary"
                    onClick={handleExcel}
                  >
                    Generar Excel
                  </Button>
                  <Button
                    style={styleM}
                    variant="contained"
                    color="secondary"
                    onClick={handlePDF}
                  >
                    Descargar Solicitud
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Box>
        <VerDocs
          open={open}
          handleClose={handleClose}
          dataDE={dataD}
          handleConsultaLote={handleConsultaLote}
        ></VerDocs>
      </div>
    </Fragment>
  );
};

export default Pruebas;

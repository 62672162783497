import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ConsultaDE = (datos) => {
  const { open, handleClose } = datos;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Button></Button>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </Dialog>
    </div>
  );
};

export default ConsultaDE;

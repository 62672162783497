import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { InputLabel, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import { getItemSession } from "../../apis/Session";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import "../../css/estilo.css";
import Switch from "@mui/material/Switch";
import PassInput from "../login/PassInput";
import { toast } from "react-toastify";
import { creaUserLimitado } from "../../apis/api";
import { listUserLimitado } from "../../apis/api";
import { deleteUserLimitado } from "../../apis/api";
import RolTable from "./RolTable";
import BorraUsr from "./BorraUsr";

const UserRol = (props) => {
  const { open, handleClose } = props;
  const [lisUser, setLisUser] = React.useState([]);

  const [user, setUser] = useState({
    ruc: getItemSession("ruc"),
    ci: "",
    nombre: "",
    mail: "",
    tel: "",
    password: "",
    passwordNew: "",
    role: "Read",
  });

  const [showPass, setShowPass] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      limpiar();
    }
  };

  const close = () => {
    setChecked(false);
    handleClose();
  };

  const [errores, setErrores] = React.useState({
    password: "",
    passwordNew: "",
  });

  const handleChange = (prop) => (event) => {
    setUser({
      ...user,
      ruc: getItemSession("ruc"),
      role: "Read",
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const limpiar = () => {
    setUser({
      nombre: "",
      ci: "",
      mail: "",
      tel: "",
      password: "",
      passwordNew: "",
    });
    setErrores({
      password: "",
      passwordNew: "",
    });
  };

  const [openD, setOpenD] = React.useState(false);

  const handleConfirmD = async () => {
    await deleteUserLimitado(user);
    let data = await listUserLimitado(getItemSession("ruc"));
    setLisUser(data);
    setOpenD(false);
    close();
  };

  const handleCloseD = async () => {
    let data = await listUserLimitado(getItemSession("ruc"));
    setLisUser(data);
    setOpenD(false);
    // close();
  };

  const handleConfirm = async () => {
    if (user.password !== user.passwordNew) {
      toast.error("LAS CONTRASEÑAS INGRESADAS NO COINCIDEN", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (
        user.nombre === "" ||
        user.password === "" ||
        user.passwordNew === ""
      ) {
        toast.error("POR FAVOR COMPLETE LOS CAMPOS REQUERIDOS", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        await creaUserLimitado(user);
        let data = await listUserLimitado(getItemSession("ruc"));
        setLisUser(data);
        close();
      }
    }
  };

  useEffect(() => limpiar(), [open]);

  useEffect(() => {
    (async () => {
      let data = await listUserLimitado(getItemSession("ruc"));
      setLisUser(data);
    })();
  }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>
          Habilitar/Deshabilitar usuarios con acceso limitado
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Habilitar/Deshabilitar usuarios con acceso limitado
          </DialogContentText> */}
          <form>
            <Grid container spacing={2}>
              <Grid size={8}>
                <RolTable
                  lisUser={lisUser}
                  setUser={setUser}
                  user={user}
                  setOpen={setOpenD}
                />
              </Grid>
              <Grid size={4}>
                <Grid size={6}>
                  <InputLabel id="admin">Usuario Limitado</InputLabel>
                </Grid>
                <Grid size={6}>
                  <Switch checked={checked} onChange={handleChangeCheck} />
                </Grid>
                <Grid size={12}>
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    fullWidth
                    variant="standard"
                    onChange={handleChange("nombre")}
                    value={user.nombre}
                    disabled={!checked}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    id="ci"
                    name="ci"
                    label="Cédula"
                    fullWidth
                    variant="standard"
                    onChange={handleChange("ci")}
                    value={user.ci}
                    disabled={!checked}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    id="mail"
                    name="mail"
                    label="Email"
                    fullWidth
                    variant="standard"
                    onChange={handleChange("mail")}
                    value={user.mail}
                    disabled={!checked}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    id="tel"
                    name="tel"
                    label="Teléfono"
                    fullWidth
                    variant="standard"
                    onChange={handleChange("tel")}
                    value={user.tel}
                    disabled={!checked}
                  />
                </Grid>
                <Grid size={12}>
                  <PassInput
                    name="password"
                    handleChange={handleChange}
                    values={user.password}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    helperText={errores.password}
                    error={errores.password === "" ? false : true}
                    adornment="Password"
                    showPassword={showPass}
                    disabled={!checked}
                  />
                </Grid>
                <Grid size={12}>
                  <PassInput
                    name="passwordNew"
                    handleChange={handleChange}
                    values={user.passwordNew}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    helperText={errores.password}
                    error={errores.password === "" ? false : true}
                    adornment="Confirmar Password"
                    showPassword={showPass}
                    disabled={!checked}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cerrar</Button>
          <Button onClick={handleConfirm}>Confirmar</Button>
        </DialogActions>
      </Dialog>
      <BorraUsr
        open={openD}
        handleClose={handleCloseD}
        user={user}
        setOpenD={setOpenD}
        handleConfirm={handleConfirmD}
      />
    </div>
  );
};

export default UserRol;
